import type { UseQueryResult } from '@tanstack/react-query';
import type { Dictionary } from 'config/types';
import type { Action, DateISOString, I18nMapDTO, LocalizedString, UUID, V4Page } from 'core/utils/basic.models';
import type { EntityState } from 'redux-ngrx-entity';
import type { Indicator, IndicatorDiagnostic, IndicatorExpression } from '../indicators/indicators.models';
import type { AreaOnTime } from '../../pages/timeline/spray.models';

export type LoadMethodologiesType = (propertyId: UUID) => Action<UUID>;

export type LoadPhenomenonByMethodologySuccessType = (phenomena: Phenomenon[]) => Action<Phenomenon[]>;

export interface CurrentSeasonAreas {
  areaId: UUID;
  areaInHectares: number;
  cropId: UUID;
  emergencyDate: DateISOString;
  id: UUID;
  methodologyId: UUID;
  plantingDate: DateISOString;
  seasonId: UUID;
  varieties: UUID[];
  varietyName: string;
}

export type PhenomenonPage = V4Page<Phenomenon>;

export interface PhenomenonCategory {
  id?: UUID;
  company_id?: UUID;
  name: I18nMapDTO;
  description?: I18nMapDTO;
  created_at?: string;
}

export enum IndicatorFeatureFlag {
  NO_TIMELINE = 'NO_TIMELINE',
  NO_HEATMAP = 'NO_HEATMAP'
}
export interface IndicatorDTO {
  cohese: boolean;
  company_id: UUID;
  created_at: UUID;
  decimal_places: number;
  description: LocalizedString;
  diagnostics: IndicatorDiagnostic[];
  expression: IndicatorExpression[];
  feature_flags: IndicatorFeatureFlag[];
  id: UUID;
  is_canonical: boolean;
  modified_at: string;
  name: LocalizedString;
  parent_id: UUID;
  phenomenon_ids: UUID[];
  short_description: LocalizedString;
  uom: LocalizedString;
}

export interface Phenomenon {
  id: UUID;
  category: PhenomenonCategory;
  characteristics: UUID[];
  company_id: UUID;
  created_at: string;
  methodologyIds?: UUID[];
  deleted_at?: string;
  description: I18nMapDTO;
  indicators: UUID[];
  indicators_dto: IndicatorDTO[];
  modified_at: string;
  name: I18nMapDTO;
  parent_id: UUID;
  short_description: I18nMapDTO;
}

export interface CropPhenomena {
  id: UUID;
  name: I18nMapDTO;
  indicatorById: Record<string, IndicatorDTO>;
}
type PhenomenaById = Record<string, CropPhenomena>;

interface MethodologiesPhenomena {
  name: I18nMapDTO;
  id: UUID;
  phenomenaById: PhenomenaById;
}

export type MethodologiesPhenomenaByCrop = Record<string, Record<string, MethodologiesPhenomena>>;

export interface PhenomenonDTO {
  category: PhenomenonCategory;
  characteristics: UUID[];
  company_id: UUID;
  created_at: string;
  deleted_at: string;
  description: I18nMapDTO;
  id: UUID;
  indicators: UUID[];
  indicators_dto: Indicator[];
  modified_at: string;
  name: I18nMapDTO;
  parent_id: UUID;
  short_description: I18nMapDTO;
}

export interface PhenomenonState extends EntityState<Phenomenon> {
  page: PhenomenonPage;
  isLoading: boolean;
  error: string | null;
  isLoadingMethodology: boolean;
  methodology: MethodologyResponse | null;
}

export interface MethodolyArea {
  activeSeason: boolean;
  areaId: UUID;
  cropId: UUID;
  methodologyId: UUID;
  varietyIds: UUID[];
}

interface MethodologyTresholds {
  color: string;
  end_value: number;
  label: {
    localized_strings: Dictionary<string>;
  };
  start_value: number;
}

interface MethodologyDiagnostics {
  thresholds: MethodologyTresholds[];
  type: string;
}

export interface MethodolyCustomIndicator {
  diagnostics: MethodologyDiagnostics[];
  feature_flags: string[];
  indicator_id: UUID;
}

export enum VariablesValidationOperation {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO'
}

enum VariablesValidationVariableType {
  VARIABLE = 'VARIABLE',
  INDEPENDENT_VARIABLE = 'INDEPENDENT_VARIABLE'
}

export enum OrderedComponentClassName {
  INDICATOR = 'OrderedIndicatorDTO',
  CHARACTERISTIC = 'OrderedCharacteristicDTO'
}

export interface VariableValidation {
  id: UUID;
  phenomenon_id: UUID;
  operation: VariablesValidationOperation;
  main_variable: {
    operand_id: UUID;
    type: VariablesValidationVariableType;
  };
  compared_with: {
    operand_id: UUID;
    type: VariablesValidationVariableType;
  };
}

export interface OrderedComponent {
  component_id: UUID;
  id: UUID;
  class_name: OrderedComponentClassName;
  priority: number;
}

export interface MethodologyResponse {
  analytic_context_dto: {
    custom_indicator_dtos: MethodolyCustomIndicator[];
    id: UUID;
  };
  cohese: boolean;
  company_id: UUID;
  created_at: string;
  crop_id: UUID;
  deleted_at: string;
  description: {
    localized_strings: Dictionary<string>;
  };
  id: UUID;
  inspection_layout: {
    characteristic_ids: UUID[];
    id: UUID;
    indicator_ids: UUID[];
    ordered_inspection_groups: {
      component_id: UUID;
      name: {
        localized_strings: Dictionary<string>;
      };
      ordered_categories: {
        component_id: UUID;
        id: UUID;
        ordered_phenomenons: {
          component_id: UUID;
          id: UUID;
          ordered_components: OrderedComponent[];
          priority: number;
        }[];
        priority: number;
      }[];
    }[];
    variables_validation: VariableValidation[];
  };
  modified_at: string;
  name: {
    localized_strings: Dictionary<string>;
  };
}

export interface PhenomenaBundle {
  content: PhenomenonDTO[];
  loaded: boolean;
}

export interface IGetAllPhenomenaByProperty {
  enabled: boolean;
}
export interface IGetAllPhenomenaByPropertyReturn {
  phenomenaDictionary: Record<UUID, Phenomenon>;
  data?: Phenomenon[];
  isLoading: boolean;
}

export interface IGetAllPhenomenaByCompany {
  enabled: boolean;
  onSuccess?: (data: Phenomenon[]) => void;
  onError?: (err: unknown) => void;
}

export interface IGetAllPhenomenaByCompanyReturn {
  phenomenaDictionary: Record<string, Phenomenon>;
  queryResult: UseQueryResult<Phenomenon[], unknown>;
}

export interface PhenomenaComparative {
  content: AreaOnTime[];
}
