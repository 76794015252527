import type { Dictionary } from 'config/types';
import { ThresholdColor } from 'core/core.models';
import type { UUID } from 'core/utils/basic.models';
import { getCurrentUnitSystem, UnitSystem } from 'core/utils/functions';
import type { CurrentSeasonArea, Property } from 'entities/property/property.models';
import { getDaeByEmergenceDay } from 'entities/season/season.functions';
import type { Set as ImmutableSet } from 'immutable';
import { Set } from 'immutable';
import moment from 'moment';
import { ProductCalculatedBy } from 'pages/tasks/task-create/tasks-create.models';
import type { ApplicationDetailResponse, AreaOnTime, Indicator } from 'pages/timeline/spray.models';
import type {
  ApplicationDetailAreaInfo,
  ApplicationDetailsArea,
  ApplicationDetailsCurrentArea,
  ApplicationDetailsCurrentAreaProp,
  ApplicationDetailsDataset,
  ApplicationDetailsInfo,
  ApplicationDetailsProduct,
  ApplicationDetailsResume,
  ApplicationDetailsTableIndicators,
  ApplicationDetailsTablePhenomenon,
  ApplicationTechnicalInfo
} from './application-details.models';

export const getWindSpeedValue = (shouldUseMeter: boolean, windSpeed: number | null): number | undefined => {
  if (!windSpeed) return undefined;

  return shouldUseMeter ? windSpeed : Math.round(windSpeed * 0.621371);
};

export const getWindSpeedLabel = (shouldUseMeter: boolean): string => (shouldUseMeter ? 'km/h' : 'mph');

export const isUnitSystemMeter = (): boolean => getCurrentUnitSystem() === UnitSystem.METRIC;

export const getFormattedWindSpeed = (windSpeed: number | null): string | null => {
  const shouldUseMeter = isUnitSystemMeter();
  const windSpeedValue = getWindSpeedValue(shouldUseMeter, windSpeed);

  if (windSpeedValue) {
    return `${windSpeedValue} ${getWindSpeedLabel(shouldUseMeter)}`;
  }

  return null;
};

export function parseAreas(input: ApplicationDetailResponse, currentArea?: ApplicationDetailsCurrentAreaProp): ApplicationDetailsArea[] {
  const sprayedAreaById = new Map(input.spray_info.areas.map(area => [area.area_id, area.area]));
  return input.areas.map<ApplicationDetailsArea>(area => {
    return {
      id: area.area_id,
      title: area.area_name,
      total_area: currentArea?.area?.total_area ?? 0,
      sprayed_area: sprayedAreaById.get(area.area_id) ?? 0,
      phenomena: parsePhenomena(area)
    };
  });
}

function parsePhenomena(area: AreaOnTime): ApplicationDetailsTablePhenomenon[] | undefined {
  const beforePhenomenon = new Map((area.before ?? []).map(phenomenon => [phenomenon.phenomenon.id, phenomenon]));
  const afterPhenomenon = new Map((area.after ?? []).map(phenomenon => [phenomenon.phenomenon.id, phenomenon]));

  const beforePhenomenaIds = beforePhenomenon.keys();
  const afterPhenomenaIds = afterPhenomenon.keys();

  const phenomenaIds: ImmutableSet<string> = new Set(
    Array.from(beforePhenomenaIds)
      .concat(Array.from(afterPhenomenaIds))
      .filter(phenomenonId => phenomenonId)
  ) as ImmutableSet<string>;

  if (!phenomenaIds.size) return undefined;

  return phenomenaIds
    .map<ApplicationDetailsTablePhenomenon>((phenomenonId: UUID) => {
      const selectedBeforePhenomenon = beforePhenomenon.get(phenomenonId);
      const selectedAfterPhenomenon = afterPhenomenon.get(phenomenonId);

      const phenomenon = (selectedBeforePhenomenon?.phenomenon ?? selectedAfterPhenomenon?.phenomenon)!;

      const beforeIndicators = selectedBeforePhenomenon?.phenomenon.indicators;
      const afterIndicators = selectedAfterPhenomenon?.phenomenon.indicators;

      return {
        id: phenomenon.id,
        name: phenomenon.name,
        short_name: phenomenon.description,
        before_count: selectedBeforePhenomenon?.count ?? 0,
        after_count: selectedAfterPhenomenon?.count ?? 0,
        before_date: moment(area.before_date),
        after_date: moment(area.after_date),
        indicators: parseIndicators(beforeIndicators, afterIndicators)
      };
    })
    .toArray();
}

function parseIndicators(beforeIndicators?: Indicator[], afterIndicators?: Indicator[]): ApplicationDetailsTableIndicators[] | undefined {
  const indicators = beforeIndicators ?? afterIndicators;

  if (!indicators?.length) return;

  const beforeIndicatorsMap = new Map((beforeIndicators ?? []).map(indicator => [indicator.id, indicator]));
  const afterIndicatorsMap = new Map((afterIndicators ?? []).map(indicator => [indicator.id, indicator]));

  return indicators.map<ApplicationDetailsTableIndicators>(indicator => {
    const beforeIndicator = beforeIndicatorsMap.get(indicator.id);
    const afterIndicator = afterIndicatorsMap.get(indicator.id);

    let before_color: string;
    let before_value: number;
    let after_color: string;
    let after_value: number;

    if (beforeIndicator) {
      before_value = beforeIndicator.result ?? beforeIndicator.double_value ?? beforeIndicator.string_value ?? 0;
      before_color = (ThresholdColor[beforeIndicator.status] as string) || ThresholdColor.NONE;
    }
    if (afterIndicator) {
      after_value = afterIndicator.result ?? afterIndicator.double_value ?? afterIndicator.string_value ?? 0;
      after_color = (ThresholdColor[afterIndicator.status] as string) || ThresholdColor.NONE;
    }

    return {
      name: indicator.name,
      uom: indicator.unit,
      before_color,
      before_value,
      after_color,
      after_value
    };
  });
}

export const ApplicationDetailsParser = (
  input: ApplicationDetailResponse,
  seasonAreasFromActiveSeasons?: CurrentSeasonArea[],
  property?: Property,
  currentArea?: ApplicationDetailsCurrentAreaProp,
  systemFlags?: Dictionary<string | boolean> | null
): ApplicationDetailsDataset => {
  return {
    info: parseInfo(),
    areas: parseAreas(input),
    technical_info: parseTechnicalInfo()
  };

  function parseTechnicalInfo(): ApplicationTechnicalInfo {
    const info = input.spray_info;
    return info.technical_info;
  }

  function parseInfo(): ApplicationDetailsInfo {
    const info = input.spray_info;
    const totalArea = info.areas.reduce((previous, current) => {
      const totalCurrentArea = currentArea?.area.total_area ? currentArea.area.total_area : 0;
      return previous + totalCurrentArea;
    }, 0);

    const totalSprayedArea = info.areas.reduce((previous, current) => {
      return previous + current.area;
    }, 0);

    return {
      scouter: info.scouter.name,
      total_area_size: totalArea,
      total_area_sprayed: totalSprayedArea,
      products: parseProducts(),
      currentArea: parseCurrent(),
      application: parseApplication(),
      areas: info.areas.map<ApplicationDetailAreaInfo>(area => ({
        id: area.area_id,
        name: area.name,
        size: area.area,
        isCurrentArea: !!(currentArea && currentArea.area.id === area.area_id),
        total_area: currentArea?.area?.total_area ?? 0
      }))
    };

    function parseApplication(): ApplicationDetailsResume {
      const total_per_tank =
        typeof info.details.amount_per_tank === 'number' && isFinite(info.details.amount_per_tank)
          ? Number(info.details.amount_per_tank?.toFixed(2))
          : 0;

      return {
        start: moment(info.start_date),
        end: moment(info.end_date),
        flow: info.details.flow_rate,
        flowUnit: info.details.flow_rate_unit,
        total: info.details.total_amount,
        totalUnit: info.details.total_amount_unit,
        mode: '',
        tank: Number(info.details.tanks?.toFixed(2)),
        total_per_tank,
        volume_last_tank: Number(info.details.volume_last_tank?.toFixed(2))
      };
    }

    function parseCurrent(): ApplicationDetailsCurrentArea | undefined {
      if (currentArea) {
        const fixingReentryPeriod = systemFlags?.P40_27164_fixing_reentry_period;
        const currentInfo = currentArea.area.current_info;

        let reentryDays = 0;
        let shortageDays = 0;

        if (currentInfo?.last_spray) {
          const endDate = moment(currentInfo.last_spray.end_date);
          reentryDays = fixingReentryPeriod
            ? currentInfo.last_spray.details.reentry_period
            : endDate.diff(moment(), 'days') + currentInfo.last_spray.details.reentry_period;

          shortageDays = fixingReentryPeriod
            ? currentInfo.last_spray.details.shortage_period
            : endDate.diff(moment(), 'days') + currentInfo.last_spray.details.shortage_period;
        }

        return {
          id: currentArea.area.id,
          regionName: currentArea.regionName,
          areaName: currentArea.area.name,
          areaSize: currentArea.area.total_area,
          seeds: currentInfo?.seeds?.join(', ') || '',
          crop: currentInfo?.crops?.[0],
          daysAfterEmergency:
            seasonAreasFromActiveSeasons && property?.id
              ? getDaeByEmergenceDay(currentArea.area, seasonAreasFromActiveSeasons, true)
              : getDaeByEmergenceDay(currentArea.area, undefined, true),
          phenologicalStage: currentInfo ? currentInfo.phenological_stage : undefined,
          reentryPeriod: reentryDays,
          shortagePeriod: shortageDays
        };
      }
      return undefined;
    }

    function parseProducts(): ApplicationDetailsProduct[] {
      return info.products.map(product => {
        const active_p = product.active_principles?.join(', ') || '';
        const active_ps = [active_p];
        return {
          id: product.id,
          name: product.name,
          type: product.type,
          dose: product.dose,
          active_principles: active_ps,
          dose_unit: product.dose_unit,
          total: product.total,
          unit: product.dose_unit,
          calculated_by: product.calculated_by || ProductCalculatedBy.AREA
        };
      });
    }
  }
};

export const scoutingScoreColors = (score: number) => {
  if (score >= 8) {
    return { fill: '#fcb300', shadow: '#D09400' };
  }
  if (score >= 6) {
    return { fill: '#A3A9B9', shadow: '#868CA2' };
  }
  return { fill: '#BD5D27', shadow: '#6B3415' };
};
