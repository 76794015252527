import type { Crop, UUID } from 'core/utils/basic.models';
import type { Region } from 'entities/region/region.models';
import type { Dictionary } from 'lodash';
import type { DailyRisk, DiseaseInfo } from 'pages/disease-risk/disease-risk.models';
import type { StoreApi, UseBoundStore } from 'zustand';
import { create } from 'zustand';
import type { WeatherBody } from '../../core/services/analytics/weather/weather.models';
import type { DiseaseRiskFieldData } from './disease-risk.models';
import './disease-risk.styles.less';

interface DiseaseRiskStoreValues {
  fieldsRisk: Dictionary<DiseaseRiskFieldData>;
  wheatRegions: Dictionary<Region>;
  wheatRegionsID: Region['id'][];
  diseaseDailyRisk?: DailyRisk;
  diseaseWeatherInfo?: WeatherBody[];
  sortedDiseasesInfo?: DiseaseInfo[];
  missingDataRegions?: Region[];
  selectedFieldId?: UUID;
  selectedFieldCrop?: Crop;
  showTutorial: boolean;
  finishTutorial: boolean;
}

export interface DiseaseRiskStoreFunctions {
  setFieldsRisk: (fieldsRisk: DiseaseRiskStoreValues['fieldsRisk']) => void;
  setWheatRegions: (wheatRegions: DiseaseRiskStoreValues['wheatRegions']) => void;
  setWheatRegionsID: (wheatRegionsID: DiseaseRiskStoreValues['wheatRegionsID']) => void;
  setDiseaseDailyRisk: (diseaseDailyRisk: DiseaseRiskStoreValues['diseaseDailyRisk']) => void;
  setDiseaseWeatherInfo: (diseaseWeatherInfo: DiseaseRiskStoreValues['diseaseWeatherInfo']) => void;
  setSortedDiseasesInfo: (sortedDiseasesInfo: DiseaseRiskStoreValues['sortedDiseasesInfo']) => void;
  setMissingDataRegions: (missingDataRegions: DiseaseRiskStoreValues['missingDataRegions']) => void;
  setSelectedFieldId: (selectedFieldId: DiseaseRiskStoreValues['selectedFieldId']) => void;
  setSelectedFieldCrop: (selectedFieldCrop: DiseaseRiskStoreValues['selectedFieldCrop']) => void;
  setShowTutorial: (showTutorial: DiseaseRiskStoreValues['showTutorial']) => void;
  setFinishTutorial: (finishTutorial: DiseaseRiskStoreValues['finishTutorial']) => void;
  resetRegions: () => void;
}

type DiseaseRiskStore = DiseaseRiskStoreValues & DiseaseRiskStoreFunctions;

const useDiseaseRiskStore: UseBoundStore<StoreApi<DiseaseRiskStore>> = create<DiseaseRiskStore>()(set => ({
  fieldsRisk: {},
  wheatRegions: {},
  wheatRegionsID: [],
  showTutorial: false,
  finishTutorial: false,
  setFieldsRisk: fieldsRisk => set({ fieldsRisk }),
  setWheatRegions: wheatRegions => set({ wheatRegions }),
  setWheatRegionsID: wheatRegionsID => set({ wheatRegionsID }),
  setDiseaseDailyRisk: diseaseDailyRisk => set({ diseaseDailyRisk }),
  setDiseaseWeatherInfo: diseaseWeatherInfo => set({ diseaseWeatherInfo }),
  setSortedDiseasesInfo: sortedDiseasesInfo => set({ sortedDiseasesInfo }),
  setMissingDataRegions: missingDataRegions => set({ missingDataRegions }),
  setSelectedFieldId: selectedFieldId => set({ selectedFieldId }),
  setSelectedFieldCrop: selectedFieldCrop => set({ selectedFieldCrop }),
  setShowTutorial: showTutorial => set({ showTutorial }),
  setFinishTutorial: finishTutorial => set({ finishTutorial }),
  resetRegions: () =>
    set({
      wheatRegions: {},
      wheatRegionsID: []
    })
}));

export { useDiseaseRiskStore as default };
