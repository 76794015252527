import type { Dictionary } from 'config/types';
import type { DateISOString, IndexedName, IndexedNameDescription, Period, UUID } from 'core/utils/basic.models';
import type { Assignee } from 'entities/assignee/assignee.models';
import type { MonitoringType } from 'entities/task/task.models';
import type { ConfigurationFile } from '../scouting/queries/confguration-file.query.models';
import type { TaskDaterangePeriod } from '../shared/task-create-daterange.models';

export interface SelectedAreas {
  id: string;
  area: number;
  selected: boolean;
}

export type DictSelectedAreas = Record<string, SelectedAreas>;

export enum TaskType {
  PRESCRIPTION = 'PRESCRIPTION',
  REGISTRATION = 'REGISTRATION',
  SCOUTING = 'SCOUTING',
  SAMPLING = 'SAMPLING'
}

export interface TaskAssignee {
  assignees: Assignee[];
  mandatory_inspection_groups: IndexedNameDescription[];
  notes: string;
  minimum_monitoring_ratio: number;
}

export enum SamplingType {
  SIMPLE = 'SIMPLE',
  COMPOSED = 'COMPOSED',
  AUTO_BY_AREA = 'AUTO_BY_AREA',
  AUTO_DISTANCE_BETWEEN = 'AUTO_DISTANCE_BETWEEN'
}

export enum TrapTaskType {
  INSTALLATION = 'INSTALLATION',
  MONITORING = 'MONITORING',
  RENEW = 'RENEW',
  UNINSTALLATION = 'UNINSTALLATION',
  REINSTALLATION = 'REINSTALLATION'
}

export enum TaskCreateType {
  SPRAY = 'SPRAY',
  PHENOMENON_MONITORING = 'PHENOMENON_MONITORING',
  SAMPLE = 'SAMPLE',
  TRAP = 'TRAP'
}

export interface TaskMonitoringArea {
  area_id: UUID;
  expected_points_for_completion: number;
  mandatory_inspection_groups: IndexedNameDescription[];
  monitoring_points: TaskPoint[];
}

export interface TaskScoutingPayload extends TaskPayload {
  id?: UUID;
  ids?: UUID[];
  type: TaskCreateType;
  periods: TaskDaterangePeriod[];
  description: string;
  notes: string;
  assignees: IndexedName[];
  minimum_monitoring_ratio: number;
  monitoring_type: MonitoringType;
  point_factor: number;
  monitoring_areas: TaskMonitoringArea[];
  company_id: UUID | null;
  property_id: UUID | null;
  region_id: UUID | null;
  season_property_id: UUID;
  inspection_groups_ids: UUID[];
  service_order_id?: string;
  start_at?: string;
  finish_until?: string;
  expected_points_for_completion?: number;
  configuration_files?: ConfigurationFile[];
  worker_order_id?: UUID;
}

export interface TaskSamplingPayload extends TaskPayload {
  id?: UUID;
  ids?: UUID[];
  type: TaskCreateType;
  assignees: IndexedName[];
  description: string; // Task name.
  notes: string;
  sample_type: SamplingType;
  monitoring_type: MonitoringType;
  minimum_monitoring_ratio: number;
  point_factor: number;
  monitoring_areas: TaskMonitoringArea[];

  company_id: UUID | null;
  property_id: UUID | null;
  region_id: UUID | null;
  season_property_id: UUID;
}

export interface SprayDefinition {
  application_operation: TaskPrescriptionApplicationOperation;
  reentry_period: Period;
  waiting_period: Period;
  tank_operation: TaskPrescriptionTankOperation;
  product_usages: TaskSprayCreate[];
  sprayed_areas: TaskSprayedAreas[];
  application_mode?: string;
  notes: string;
  custom_fields?: Dictionary<string> | null;
}

export interface TaskSprayPayload extends TaskPayload {
  start_at: DateISOString;
  finish_until: DateISOString;
  spray_definition: SprayDefinition;
}

export interface TaskSprayPayloadResponse {
  content: TaskSprayPayload[];
  last: boolean;
}

interface AppliedProduct {
  product_id: UUID;
  quantity: number;
}

export interface TaskPrescriptionCreate extends TaskPayload {
  start_at: DateISOString;
  finish_until: DateISOString;
  periods: TaskDaterangePeriod[];
  spray_definition: {
    application_operation: TaskPrescriptionApplicationOperation;
    applied_products?: AppliedProduct[] | null;
    reentry_period: Period;
    waiting_period: Period;
    id?: string;
    tank_operation: TaskPrescriptionTankOperation;
    product_usages: TaskSprayCreate[] | TaskApplicationProduct[];
    sprayed_areas: TaskSprayedAreas[];
    notes: string;
    custom_fields?: Dictionary<string> | null;
  };
  spray_id?: UUID;
  operation_id?: UUID;
}

export interface TaskTrapPayload extends TaskPayload {
  id?: UUID;
  type: TaskCreateType;
  description: string;
  notes: string;
  assignees: IndexedName[];
  minimum_execution_ratio: number;
  trap_task_type: TrapTaskType;
  trap_areas: TrapArea[];
  company_id: UUID | null;
  property_id: UUID | null;
  region_id: UUID | null;
  season_property_id: UUID;
  service_order_id?: string;
}

export interface TrapArea {
  area_id: UUID;
  trap_template_id: UUID;
  number_of_traps: number;
  trap_instances: TrapInstance[];
}

interface TrapInstance {
  trap_id: UUID;
  coordinates: GeoJSON.Point;
}

export interface TaskPayload {
  id?: UUID;
  ids?: UUID[];
  service_order_id?: string;
  operation_id?: string;
  owner?: IndexedName;
  owner_id?: UUID;
  assignees: IndexedName[];
  periods: TaskDaterangePeriod[];
  type: TaskCreateType;
  description: string;
  notes: string;
  company_id: UUID | null;
  property_id: UUID | null;
  region_id: UUID | null;
  season_property_id: UUID;
  season_id: UUID;
}

export interface TaskRegistrationOperations {
  operation_id: UUID;
  area_id: UUID | undefined;
  areaId?: UUID;
  operationId?: UUID;
}

export interface TaskRegistrationCreate {
  id: UUID;
  property_id: UUID;
  region_id: UUID | null;
  task_id: UUID;
  company_id: UUID;
  assignees: IndexedName[];
  description: string;
  scouter_id: UUID;
  scouter_name: string;
  created_at: DateISOString;
  start_date: DateISOString;
  end_date: DateISOString;
  is_split_by_tank: boolean;
  total_sprayed_area: number;
  quantity_spray: number;
  flow_rate: number;
  number_of_tanks: number;
  quantity_per_tank: number;
  quantity_last_tank: number;
  temperature: number | null;
  season_property_id: UUID;
  relative_humidity: number | null;
  wind_speed: number | null;
  season_id: UUID;
  wind_direction: WindDirectionOptions;
  waiting_period: number;
  reentry_period: number;
  pointer: string;
  notes: string;
  angle: string;
  drop_size: string;
  tip_rating: string;
  flow_rate_unit: string;
  spray_mode: SprayMode;
  soil_humidity: SoilHumidity;
  spray_areas: TaskSprayedAreas[];
  spray_products: TaskApplicationProduct[];
  vehicles_ids?: UUID[];
  implements_ids?: UUID[];
  operations?: TaskRegistrationOperations[] | null;
  custom_fields?: Dictionary<string> | null;
}

export interface TaskPoint {
  service_order_id: string | null;
  sampling_group_id: UUID | null;
  coordinates: GeoJSON.Point;
}

interface TaskSprayTarget {
  id: UUID;
  name: string;
  short_description: string;
}

export enum ProductCalculatedBy {
  AREA = 'AREA',
  VOLUME = 'VOLUME',
  TANK = 'TANK'
}

export interface TaskApplicationProductWarehouse {
  warehouse_id: UUID;
  quantity: number;
}

export interface TaskSprayCreate {
  order: number;
  product_id: UUID;
  action_mode: string;
  uom: string;
  calculated_by: ProductCalculatedBy;
  concentration: number;
  quantity: number;
  targets: TaskSprayTarget[];
  warehouse_usages?: TaskApplicationProductWarehouse[];
  warehouses: Dictionary<TaskSprayWarehouse>;
}

export interface TaskApplicationProduct {
  product_id: UUID;
  sequence?: number;
  order: number;
  action_mode?: string;
  uom: string;
  calculated_by: ProductCalculatedBy;
  concentration: number;
  quantity: number;
  targets: IndexedNameDescription[];
  warehouse_usages?: TaskApplicationProductWarehouse[];
}

interface TaskSprayWarehouse {
  id: UUID;
  name: string;
  quantity: number;
}

export interface TaskPrescriptionApplicationOperation {
  flow: number;
  flow_unit: string;
  spray_quantity: number;
}

export interface TaskPrescriptionTankOperation {
  is_split_by_tank: boolean;
  number_of_tanks: number;
  quantity_on_last_tank: number;
  tank_capacity: number;
  uom: string;
}

export interface Asset {
  id: string;
  name: string;
  implementType: {
    name: string;
  };
  type: AssetTypes;
  property_ids: string[];
}

export enum SoilHumidity {
  WET = 0,
  DRY = 1,
  MOIST = 2,
  UNKNOWN = 'UNKNOWN'
}

export enum AssetTypes {
  MACHINE = 'MACHINE',
  IMPLEMENT = 'IMPLEMENT',
  VEHICLE = `VEHICLE`
}

export enum SprayMode {
  TERRESTRIAL = 0,
  AERIAL = 1,
  COASTAL = 2
}

export enum WindDirectionOptions {
  SOUTH = 'SOUTH',
  SOUTH_EAST = 'SOUTHEAST',
  SOUTH_WEST = 'SOUTHWEST',
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTHEAST',
  NORTH_WEST = 'NORTHWEST',
  EAST = 'EAST',
  WEST = 'WEST'
}

export interface IAdditionalInfoInputs {
  waitingPeriod: number;
  reentryPeriod: number;
  notes: string;
  assigneeID: UUID;
}

export interface TaskAssigneeOption {
  assigneeType: string;
  id: UUID;
  name: string;
  workerRole?: string;
}

export interface TaskSprayedAreas {
  affected_area_in_hectares: number;
  area_id: UUID;
}
