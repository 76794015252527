import { ofType, type ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import type { Action } from '../../core/utils/basic.models';
import { LoadTasksFailure, LoadTasksSuccess, TasksActionTypes } from './task.actions';
import type { TaskLoadType, TaskPage } from './task.models';
import { getTaskPage } from './task.service';

export const handleLoadTasks = (action$: ActionsObservable<Action<TaskLoadType>>) =>
  action$.pipe(
    ofType(TasksActionTypes.LOAD_TASKS_REQUEST),
    map((action: Action<TaskLoadType>) => (action.payload ? action.payload : ({} as TaskLoadType))),
    concatMap((payload: TaskLoadType) =>
      getTaskPage(
        payload.propertyId,
        payload.regions,
        payload.starting_from,
        payload.ending_until,
        payload.page,
        payload.type,
        payload.status,
        payload.size,
        payload.search_str,
        payload.seasonId,
        payload.areas
      ).pipe(
        map(response => response.data),
        map((tasksData: TaskPage) => {
          return LoadTasksSuccess(tasksData);
        }),
        catchError((error: string) => of(LoadTasksFailure(error)))
      )
    )
  );
