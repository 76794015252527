import type { DateISOString, UUID } from 'core/utils/basic.models';
import type { DiseasePressureByDisease } from './disease-risk-configuration/disease-risk-historical-disease-pressure/disease-risk-historical-disease-pressure.types';

/** Client side risks. -1: None, 0: Acceptance, 1: Control, 2: Damage */
export type RiskFrontend = -1 | 0 | 1 | 2;

/** Server side risks. 3: None, 0: Acceptance, 1: Control, 2: Damage */
export type RiskBackend = 0 | 1 | 2 | 3;

export const riskOneDayTabColors: Record<RiskFrontend, string> = {
  [0]: '#19A04B',
  [1]: '#FFA858',
  [2]: '#CF3537',
  [-1]: '#707374'
};

interface DailyRiskBaseDto {
  date: DateISOString;
  risk: RiskBackend;
}

interface DailyRiskDto extends DailyRiskBaseDto {
  leafInformation?: {
    incubation: string[];
    sympton: string[];
  };
  messageLabel?: string;
  plantBBCHNum: number;
  disease?: string;
}

export type DailyRisk = Omit<DailyRiskDto, 'risk'> & {
  risk: RiskFrontend;
};

export enum RiskType {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NO_RISK = 'NO_RISK',
  MISSING_DATA = 'MISSING_DATA',
  SEASON_ENDED = 'SEASON_ENDED'
}

export enum Disease {
  YELLOW_RUST = 'Yellow Rust',
  BROWN_RUST = 'Brown Rust',
  SEPTORIA = 'Septoria',
  POWDERY_MILDEW = 'Powdery Mildew',
  FUSARIUM = 'Fusarium',
  RHYNCHOSPORIUM = 'Rhyncho-sporium',
  RAMULARIA = 'Ramularia',
  LEAF_RUST = 'Leaf Rust',
  NET_BLOTCH = 'Net Blotch',
  EYESPOT = 'Eyespot'
}

interface DiseaseInfoDto {
  dates: DailyRiskDto[];
  disease: string;
  risk: RiskBackend;
}

export type DiseaseInfo = Omit<DiseaseInfoDto, 'risk' | 'dates'> & {
  dates: DailyRisk[];
  risk: RiskFrontend;
};

export interface DiseaseRiskStatus {
  error: string | null;
  error_code?: string;
  error_original?: string;
  processedAt: string;
  requestedAt: string;
  status: string;
}

export interface DiseaseRisksDto {
  fields: DiseaseRiskFieldDataDto[];
}

interface DiseaseRiskFieldDataDto {
  cropId: string[];
  diseases: DiseaseInfoDto[];
  fieldId: UUID;
  risk: RiskBackend;
  status: DiseaseRiskStatus;
  simulation: DiseaseRiskSimulation | null;
}

export type DiseaseRiskFieldData = Omit<DiseaseRiskFieldDataDto, 'risk' | 'diseases'> & {
  diseases?: DiseaseInfo[];
  riskFieldList?: RiskFrontend;
  riskFieldDetails?: RiskFrontend;
  riskMap?: RiskFrontend;
};
export interface DiseaseRisksPotatoDto {
  fields: DiseaseRiskFieldDataPotatoDto[];
}

export interface DiseaseRiskFieldDataPotatoDto {
  diseases: DiseaseInfoPotatoDto[];
  fieldId: UUID;
  risk: RiskBackend;
  status: DiseaseRiskStatus;
}

export interface DiseaseInfoPotatoTreatmentAdvice {
  adviceMessageSlug?: string | null;
  adviceProductType: string | null;
  adviceTreatmentDate: DateISOString | null;
  productPersistance?: number | null;
}

export interface DiseaseInfoPotatoDto {
  disease: string;
  todayData: DiseasePotatoDataDto[];
  tomorrowData: DiseasePotatoDataDto[];
  sixDaysData: DiseasePotatoDataDto[];
  risk: RiskBackend;
  treatmentAdvice: DiseaseInfoPotatoTreatmentAdvice;
}

export interface DiseasePotatoDataDto extends DailyRiskBaseDto {
  protection: boolean;
  contaminationWeight: number | null;
  sporulationPotential: number | null;
  sporeReserve: number | null;
  contaminationIndex: number | null;
  rainfastness: number | null;
  rainfallAmount: number | null;
  isPast: boolean;
  updatedAt: DateISOString | null;
}

export type DiseaseRiskFieldDataPotato = Omit<DiseaseRiskFieldDataPotatoDto, 'diseases' | 'risk'> & {
  diseases: DiseaseInfoPotato[];
  risk: RiskFrontend;
};

export type DiseaseInfoPotato = Omit<DiseaseInfoPotatoDto, 'todayData' | 'tomorrowData' | 'sixDaysData' | 'risk'> & {
  todayData: DiseasePotatoData[];
  tomorrowData: DiseasePotatoData[];
  sixDaysData: DiseasePotatoData[];
  risk: RiskFrontend;
};

export type DiseasePotatoData = Omit<DiseasePotatoDataDto, 'risk'> & {
  risk: RiskFrontend;
};

interface DiseaseRiskSimulation {
  simDateCalculated: string;
  simId: number;
}

export enum RiskColors {
  NO_DATA = 'transparent',
  NONE = '#C1C5C8',
  ACCEPTANCE = '#90D6AA',
  CONTROL = '#FFA858',
  DAMAGE = '#CF3537'
}

export type SoilType = 'calcareous' | 'organic';

// this is a mix of DiseaseRiskConfigurations and other props. This will be refactored in the future to be called DiseaseRiskExtendedConfigurations (like it is done for grapes)
export interface FieldDiseaseRiskConfigurations {
  fieldId: string;
  ploughed?: boolean;
  previousCrop?: string;
  propertyId: string;
  soilTexture?: string;
  isMissingMandatoryAttribute: boolean;
  yieldPotential?: number | null;
  userId?: string | null;
  fieldName?: string;
  fieldSeeds?: string[];
  plantingDate?: string;
  harvestDate?: string;
  emergenceDate?: string;
  nitrogenLevel?: number | null;
  soilType?: SoilType[];
  antePreviousCrop?: string | null;
  yieldPotentialDate?: number | null;
}

export interface DiseaseRiskAdvancedConfigurations {
  soilType: SoilType[];
  antePreviousCrop: string | null;
  yieldPotential: number | null;
  nitrogenLevel: number | null;
}

export interface DiseaseRiskConfigurations {
  fieldId: string;
  ploughed: boolean;
  previousCrop: string;
  propertyId: string;
  soilTexture: string;
  soilType?: SoilType[];
  yieldPotential?: number | null;
  antePreviousCrop: string | null;
  yieldPotentialDate?: number | null;
  nitrogenLevel?: number | null;
}

export interface DiseaseRiskConfigurationsUpdatePayload {
  ploughed: boolean | null;
  antePreviousCrop?: string | null;
  previousCrop?: string;
  soilType?: SoilType[];
  soilTexture?: string;
  yieldPotential?: number | null;
  nitrogenLevel?: number | null;
}

export interface DiseaseRiskFieldMissingConfigurations {
  fieldId: string;
  fieldName: string;
  fieldSeeds?: string[];
  plantingDate?: string;
  harvestDate?: string;
  emergenceDate?: string;
  emptyConfiguration: true;
  soilType?: string[];
  antePreviousCrop?: string | null;
  yieldPotentialDate?: number | null;
  yieldPotential?: number | null;
  nitrogenLevel?: number | null;
}

/** 1: low, 2: medium, 3: high */
export type GrapesDiseaseSensitivity = 1 | 2 | 3;
export type GrapesCertification = 'organic' | 'conventional';

export interface DiseaseRiskGrapesConfigurationsDto {
  fieldId: string;
  certification: GrapesCertification;
  intervineSpacing: number;
  interrowSpacing: number;
  sensitivityMildew: GrapesDiseaseSensitivity;
  sensitivityOidium: GrapesDiseaseSensitivity;
  attackOidium: boolean;
}

export interface DiseaseRiskGrapesConfigurations {
  fieldId: string;
  certification: GrapesCertification;
  intervineSpacing: number;
  interrowSpacing: number;
  sensitivityMildew: GrapesDiseaseSensitivity;
  sensitivityOidium: GrapesDiseaseSensitivity;
  attackOidium: boolean;
}

export type DiseaseRiskGrapesExtendedConfigurations = DiseaseRiskGrapesConfigurations & {
  fieldName: string;
  harvestDate?: string;
  fieldSeeds?: string[];
};

export interface DiseaseRiskGrapesConfigurationsPost {
  certification: GrapesCertification;
  intervineSpacing: number;
  interrowSpacing: number;
  sensitivityMildew: GrapesDiseaseSensitivity;
  sensitivityOidium: GrapesDiseaseSensitivity;
  attackOidium: boolean;
}

export interface DiseaseRiskGrapesConfigurationsPostResult {
  OK: boolean;
}

export const soilTextures = [
  'Clay',
  'Clay loam',
  'Loam',
  'Loamy sand',
  'Sand',
  'Sandy clay',
  'Sandy clay loam',
  'Sandy Loam',
  'Silt',
  'Silt loam',
  'Silty clay',
  'Silty clay loam'
];

export const cropOptions = [
  'BARLEY',
  'CORN SEED',
  'CORN SILAGE',
  'FALLOW',
  'FLAX',
  'LUPINE',
  'MILLET',
  'OILSEED RAPE',
  'OTHERS',
  'PEAS',
  'PERENNIAL RYEGRASS',
  'POTATO',
  'SORGHUM',
  'SOYBEAN',
  'SUGAR BEET',
  'SUNFLOWER',
  'VEGETABLES',
  'VICIA FABA',
  'WHEAT'
];

export const antePreviousCropOptions = ['CORN', 'STRAWS', 'OTHERS'];

export interface SprayWindowDailySummaryRequestBody {
  location: number[];
  country_code: string;
}

export interface SprayWindowDailySummaryDatum {
  best_to_spray: number;
  can_spray: number;
  not_advisable: number;
  data_not_available: number;
  date: string;
}

export interface PhenologicalStagesModel {
  area_id: UUID;
  days: number;
  development_stage_id: UUID;
  finish_time: DateISOString;
  id: UUID;
  start_time: DateISOString;
  phenology_date?: string;
}

export type DiseasePressure = 0 | 1 | 2 | 3 | 4 | 5;

interface DiseaseRiskHistoricalDiseasePressureDisease {
  disease: string;
  value: DiseasePressure;
}

export interface DiseaseRiskHistoricalDiseasePressureField {
  fieldId: UUID;
  pressurePreviousSeason: DiseaseRiskHistoricalDiseasePressureDisease[];
  pressureCurrentSeasonBeforeBbch30: DiseaseRiskHistoricalDiseasePressureDisease[];
}

export interface PatchHistoricalDiseasePressureProps {
  propertyId: UUID;
  fieldIds: UUID[];
  historicalDiseasePressurePatch: {
    pressureCurrentSeasonBeforeBbch30: DiseasePressureByDisease[];
    pressurePreviousSeason: DiseasePressureByDisease[];
  };
}

export interface EditDrawerConfig {
  areaName: string;
  emergencyDate: string | null;
  harvestingDate: string | null;
  id: string | undefined;
  plantingDate?: string | null;
  soilTexture?: string | null;
  varieties: string[] | string;
}
