import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SVGFieldClusterGlyph from 'st-protector-icons/Glyph/FieldClusterGlyph';
import { StyledTitle } from './field-cluster-modal.styles';

const FieldClusterModalTitle = () => {
  const [t] = useTranslation();

  return (
    <StyledTitle data-testid='field-cluster-modal-title'>
      <SVGFieldClusterGlyph />
      <STTypo fontWeight={500} type={TypeOptions.H4}>
        {t('modals.organization_settings.field_cluster_card.modal.title')}
      </STTypo>
    </StyledTitle>
  );
};

export default React.memo(FieldClusterModalTitle);
