import { uuid4 } from '@sentry/utils';
import { Button, DatePicker, Drawer, Form, Popconfirm } from 'antd';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import { getCurrentLanguage } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { RequestReport } from 'entities/reports/report.actions';
import moment from 'moment';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';

const { RangePicker } = DatePicker;

export interface ISTReportRainGaugeDrawer {
  callbackDrawer: () => void;
  visible?: boolean;
  icon?: any;
}

const STReportRainGaugeDrawer: React.FC<ISTReportRainGaugeDrawer> = ({ visible, callbackDrawer, icon }) => {
  const [t] = useTranslation();
  const [timeRangeFrom, setTimeRangeFrom] = useState(moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [timeRangeTo, setTimeRangeTo] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [finish, setFinish] = useState<boolean>(false);
  const dispatch = useDispatch();
  const property = useSelector(getSelectedProperty);
  const currentUser = useSelector(selectCurrentUser);

  const company = useSelector(getSelectedCompany);
  const currentLanguage = getCurrentLanguage();

  const handleTimeRange = value => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const closeDrawer = useCallback(() => {
    setTimeRangeFrom(moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    setTimeRangeTo(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    callbackDrawer();
  }, [callbackDrawer]);

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (finish) {
      setFinish(false);
      closeDrawer();
    }
  }, [finish, closeDrawer]);

  const segmentTracking = useSegmentTracking();

  const onConfirm = () => {
    if (!property?.id || !company?.id) return;
    const requestBody = {
      id: uuid4(),
      type: ReportsTypes.RAIN_GAUGE_REPORT,
      property_id: property.id,
      data_request: {
        user_id: currentUser?.id,
        language: currentLanguage.toLowerCase(),
        start_date: timeRangeFrom.format('YYYY-MM-DD'),
        end_date: timeRangeTo.format('YYYY-MM-DD'),
        property_id: property.id
      }
    };

    segmentTracking.track('Export Report - Generated Report');

    dispatch(RequestReport(requestBody));
    closeDrawer();
  };
  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={ReportsTypes.RAIN_GAUGE_REPORT} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-rain-gauge-drawer'>
        <ExplicativeText type={ReportsTypes.RAIN_GAUGE_REPORT} />
        <div className='st-report-drawer__form' data-testid='report-rain-gauge-form'>
          <Form layout='vertical'>
            <Form.Item data-testid='report-rain-gauge-type-timerange' label={t('modals.timeline_xray_report.fields.time_range')}>
              <RangePicker
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                size='large'
                separator='—'
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Form>
        </div>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={() => closeDrawer()}
            okText={t('general.complete')}
            okButtonProps={{ style: { backgroundColor: '#F74141', border: 'none', borderRadius: '4px', paddingTop: '2px' } }}
            cancelButtonProps={{
              style: {
                backgroundColor: '#F2F4F6',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px',
                color: ColorOptions.PRIMARY
              }
            }}
            cancelText={t('general.back')}>
            <Button type='ghost' size='default' className='st-report-drawer_actions-cancel' data-testid='report-rain-gauge-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={() => onConfirm()}
            disabled={!timeRangeFrom && !timeRangeTo}
            data-testid='report-rain-gauge-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default STReportRainGaugeDrawer;
