import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CORE_SERVICES_API_URL, PROTECTOR_API_URL } from 'config/constants';
import type { DateISOString, UUID } from 'core/utils/basic.models';
import { getCurrentLanguage } from 'core/utils/functions';
import type { PhenomenonSingle } from 'entities/methodology-deep/methodology-deep.models';
import _ from 'lodash';
import moment from 'moment';
import type { MonitoringDetailPhenomenon } from 'pages/timeline/monitoring.models';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { concatMap } from 'rxjs/operators';
import type { CurrentSeasonAreas, MethodologyResponse, MethodolyArea, Phenomenon, PhenomenonPage } from './phenomenon.models';

const protectorApiUrl = PROTECTOR_API_URL;
const coreServicesApiUrl = CORE_SERVICES_API_URL;
const phenomenonUrl = `${protectorApiUrl}/api/v1/companies`;
const phenomenaByIdsUrl = `${protectorApiUrl}/api/v1/phenomena/ids`;
const samplePointsUrl = `${protectorApiUrl}/v1/analytics/monitoring/detail`;
const indicatorUrl = `${protectorApiUrl}/api/v1/indicators`;
const demeterMethodologyUrl = `${protectorApiUrl}/api/v1/methodologies`;

export const getPhenomenaByIds = (ids: string[]) => {
  return axios.post<Phenomenon[]>(phenomenaByIdsUrl, { ids }).pipe(map(response => response.data));
};

export const getIndicatorInfo = (id: UUID) => {
  return axios.get<any>(`${indicatorUrl}/${id}`).pipe(map(response => response.data));
};

const fetchPhenomenaPage = async (companyId: string, page: number): Promise<PhenomenonPage> => {
  const response = await axios.get<PhenomenonPage>(`${phenomenonUrl}/${companyId}/phenomenons?page=${page}&size=500`).toPromise();
  return response.data;
};

export const getPhenomena = async (companyId: string): Promise<Phenomenon[]> => {
  const fromStorage = sessionStorage.getItem(`phenomenon_${companyId}`);

  if (fromStorage) {
    return JSON.parse(fromStorage);
  }

  const initialResponse = await fetchPhenomenaPage(companyId, 0);

  let phenomenons = initialResponse.content;
  const totalPages = initialResponse.total_pages;

  const pagePromises: Promise<Phenomenon[]>[] = [];

  for (let currentPage = 1; currentPage < totalPages; currentPage++) {
    pagePromises.push(fetchPhenomenaPage(companyId, currentPage).then(response => response.content));
  }

  const allPages = await Promise.all(pagePromises);

  phenomenons = phenomenons.concat(...allPages);

  try {
    sessionStorage.setItem(`phenomenon_${companyId}`, JSON.stringify(phenomenons));
  } catch {
    return phenomenons;
  }

  return phenomenons;
};

export const getMethodologies: (propertyId) => Observable<CurrentSeasonAreas[]> = propertyId => {
  const methodologiesUrl = `${coreServicesApiUrl}/v1/properties/${propertyId}/current-season-areas?date=${moment().format(
    'YYYY-MM-DD'
  )}&includeMethodology=true`;

  return axios.get<any>(methodologiesUrl).pipe(map(response => response.data));
};

export const getPhenomenaByMethodology: (methodologyIds) => Observable<any> = methodologyIds => {
  return forkJoin(
    methodologyIds.map(id => {
      const methodologyUrl = `${protectorApiUrl}/api/v1/methodologies/${id}/phenomenon`;
      return axios.get(methodologyUrl).pipe(
        map(response => response.data),
        map(phenomena => {
          return { [id]: phenomena };
        })
      );
    })
  );
};

const pipePhenomena = (methodologiesObservable: Observable<any>, companyId: UUID) => {
  const currentLanguage = getCurrentLanguage();
  // Get all company's methodologies
  return methodologiesObservable.pipe(
    concatMap((methodologies: any[]) => {
      // Get methodology's phenomena
      return forkJoin(
        methodologies.map(methodology =>
          axios.get(`${protectorApiUrl}/api/v1/methodologies/${methodology.id}/phenomenon`).pipe(
            map(response => response.data),
            map(phenomena => {
              return phenomena.map(phenomenon => {
                return {
                  id: phenomenon.id,
                  name: phenomenon.name.localized_strings[currentLanguage]?.length
                    ? phenomenon.name.localized_strings[currentLanguage]
                    : phenomenon.name.localized_strings.en
                };
              });
            })
          )
        )
      );
    }),
    map(methodologyPhenomena => _.flatten(methodologyPhenomena)),
    concatMap((phenomena: any[]) => {
      return forkJoin(
        phenomena.map(phenomenon =>
          axios.get(`${protectorApiUrl}/api/v1/indicators/by-phenomenon/${phenomenon.id}?companyId=${companyId}`).pipe(
            map(response => response.data),
            map(page => {
              const indicators = page.content;
              // Get phenomenon's indicators
              return {
                ...phenomenon,
                indicators: indicators.map(indicator => {
                  return {
                    id: indicator.id,
                    name: indicator.name.localized_strings[currentLanguage]?.length
                      ? indicator.name.localized_strings[currentLanguage]
                      : indicator.name.localized_strings.en
                  };
                })
              };
            })
          )
        )
      );
    })
  );
};

export const getPhenomenaAndIndicator = (companyId: UUID, areaId: UUID | null) => {
  let methodologiesObservable = new Observable();
  if (areaId === null) {
    methodologiesObservable = axios
      .get(`${protectorApiUrl}/api/v1/methodologies/by-company/${companyId}`, {
        headers: {
          'X-Company-Id': companyId
        }
      })
      .pipe(map(response => response.data.content));
  } else {
    const date = new Date();
    const isoDate = date.toISOString().replace(date.toISOString().slice(-5), '+0000');
    methodologiesObservable = axios.post(`${protectorApiUrl}/v1/methodologies/areas/`, { areaIds: [areaId], date: isoDate }).pipe(
      map(response => response.data),
      map(methodologies =>
        methodologies.map(r => {
          return {
            id: r.methodologyId
          };
        })
      )
    );
  }
  return pipePhenomena(methodologiesObservable, companyId);
};

export const getMethodologyByArea = (areaId: UUID) => {
  const date = new Date();
  const isoDate = date.toISOString().replace(date.toISOString().slice(-5), '+0000');
  return axios
    .post<MethodolyArea[]>(`${protectorApiUrl}/v1/methodologies/areas`, { areaIds: [areaId], date: isoDate })
    .pipe(map(response => response.data));
};

export const getMethodology = (methodologyId: UUID) => {
  return axios.get<MethodologyResponse>(`${protectorApiUrl}/api/v1/methodologies/${methodologyId}`).pipe(map(response => response.data));
};

export const getPhenomenaSamplePoints = (
  property_id: UUID,
  area_id: UUID,
  start: DateISOString,
  end: DateISOString
): AxiosObservable<{ phenomena: MonitoringDetailPhenomenon[] }> => {
  return axios
    .get<{ phenomena: MonitoringDetailPhenomenon[] }>(`${samplePointsUrl}`, {
      params: { property_id, area_id, start, end }
    })
    .pipe(
      map(response => {
        return response;
      })
    );
};

export const getPhenomenaByMethodologyId = (methodologyId: UUID): AxiosObservable<PhenomenonSingle[]> => {
  return axios.get<PhenomenonSingle[]>(`${demeterMethodologyUrl}/${methodologyId}/phenomenon`);
};

export const getPhenomenaByCompany = (company_id: UUID) => {
  return axios.get<PhenomenonPage>(`${phenomenonUrl}/${company_id}/phenomenons?size=9999`).pipe(map(response => response.data));
};
