import * as Sentry from '@sentry/react';
import { Modal, Typography } from 'antd';
import STTypo, { STTypoModels } from 'core/shared/typo';
import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SvgBug from 'st-protector-icons/Minor/Bug';
import './something-went-wrong.styles.less';

export const SomethingWentWrong: React.FC<Parameters<Sentry.FallbackRender>[0]> = ({ error, eventId }) => {
  const [t] = useTranslation();

  const openUserFeedback = () => {
    Sentry.showReportDialog({
      eventId: eventId ?? undefined,
      title: t('whoops.sentry.title'),
      subtitle: t('whoops.sentry.subtitle'),
      subtitle2: t('whoops.sentry.subtitle2'),
      labelName: t('whoops.sentry.labelName'),
      labelComments: t('whoops.sentry.labelComments'),
      labelClose: t('whoops.sentry.labelClose'),
      labelSubmit: t('whoops.sentry.labelSubmit'),
      successMessage: t('whoops.sentry.successMessage')
    });
  };

  const isChunkError = /failed to fetch/i.test(error.message);

  useEffect(() => {
    if (isChunkError) {
      Modal.info({
        title: t('modals.update.title'),
        content: <Typography.Text>{t('modals.update.description')}</Typography.Text>,
        onOk: () => window.location.reload(),
        okText: t('modals.update.button'),
        centered: true,
        keyboard: false,
        transitionName: '',
        maskTransitionName: ''
      });
    }
  }, [isChunkError, t]);

  if (isChunkError) {
    return null;
  }

  return (
    <div className='st-something-went-wrong'>
      <div className='st-something-went-wrong__bug-icon'>
        <SvgBug />
      </div>
      <div className='st-something-went-wrong__description'>
        <STTypo type={STTypoModels.TypeOptions.H1}>Whoops!</STTypo>
        <STTypo type={STTypoModels.TypeOptions.H2}>{t('whoops.whoops.message')}</STTypo>
        <STTypo>
          {t('whoops.whoops.automaticamente')}{' '}
          <a href='#/' data-testid='whoops-open' onClick={openUserFeedback}>
            {t('whoops.whoops.btn')}
          </a>{' '}
          {t('whoops.whoops.help')}
        </STTypo>
      </div>
    </div>
  );
};
