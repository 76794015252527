import type { Dictionary } from 'config/types';
import type { AreaWithoutGeometryFeatures } from 'core/shared/enums/featureFlags.enum';
import type { GeoJsonObject, Polygon } from 'geojson';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { getSeverityColorFromSeverity } from 'pages/timeline/timeline.functions';
import type { ListMultimap, Nullable } from '../../core/core.models';
import type { UUID } from '../../core/utils/basic.models';
import { createDictionaryFromList, hashNumber } from '../../core/utils/functions';
import type { Crop, Variety } from '../crop/crop.models';
import type { FieldSeason, IndicatorPressureResponse } from '../property/property.models';
import type { Season, SeasonFlat } from '../season/season.models';
import type {
  AreaInfo,
  BuildAreaInfoBySeasonFieldProps,
  CurrentInfo,
  Field,
  GeometryProperties,
  GetVarietiesNamesFromSeasonFieldByRegionSeasonsParams,
  Region,
  RegionGeometry,
  SeasonArea,
  UpdateFeaturePropertiesParams,
  V1Region
} from './region.models';
import { RegionType, SeasonAreaUpdateType } from './region.models';
import type { UpdateSeasonAreasMassivelyPayload } from './region.service';

/**
 * Build Regions recursive. The children are filled until be fields not abstracts.
 * @param parentRegion {Region} Parent region to fill children.
 * @param fieldsByParentRegion {ListMultimap<Field>} Fields segregated by parent region.
 * @param childrenByParent {ListMultimap<Region>} Regions segregated by parent region.
 * @param seasonFieldsById {Dictionary<SeasonField>} Season Fields by id.
 * @param cropsById Crops by id.
 * @param varietiesById Varieties by id.
 * @param seasons {SeasonFlat[]} Seasons.
 */

const buildRegion = (
  parentRegion: Region,
  fieldsByParentRegion: ListMultimap<Field>,
  childrenByParent: ListMultimap<Region>,
  seasonFieldsById: Dictionary<FieldSeason>,
  seasons: Season[],
  cropsById: Dictionary<Crop>,
  varietiesById: Dictionary<Variety>
) => {
  const regionsByParent = childrenByParent[parentRegion.id] || [];
  const fieldsByParent = fieldsByParentRegion[parentRegion.id] || [];
  const fields = fieldsByParent.map(field =>
    buildRegionAreaFromField(field, seasonFieldsById[field.id], seasons, cropsById, varietiesById)
  );
  const regions = regionsByParent.map(c =>
    buildRegion(c, fieldsByParentRegion, childrenByParent, seasonFieldsById, seasons, cropsById, varietiesById)
  );
  const seasonsByFields = fields.flatMap(f => f.seasons);
  const seasonsByRegions = regions.flatMap(r => r.seasons);
  return {
    ...parentRegion,
    children: [...fields, ...regions],
    seasons: [...new Map([...seasonsByFields, ...seasonsByRegions].map(variety => [variety.id, variety])).values()]
  };
};

const getAreaRegions = (regions: Dictionary<Region>): Region[] =>
  Object.values(regions).filter(region => !region.children || region.children.length === 0);

const getNonAreaRegions = (regions: Dictionary<Region>): Region[] => {
  const areaRegionIds = getAreaIdsFromRegions(regions);
  return Object.values(regions).filter(region => !areaRegionIds.includes(region.id));
};

export const getAreaIdsFromRegions = (regions: Dictionary<Region>): UUID[] => getAreaRegions(regions).map(region => region.id);

const isFeatureCollection = (geometry: RegionGeometry | undefined): geometry is GeoJSON.FeatureCollection => {
  if (!geometry) return false;
  return 'features' in geometry;
};

const updateFeatureProperties = ({ feature, regionUpdatesIds, regionGeometryPropertiesMap }: UpdateFeaturePropertiesParams) => {
  if (feature.properties && regionUpdatesIds.has(feature.properties.id)) {
    feature.properties = {
      ...feature.properties,
      ...regionGeometryPropertiesMap[feature.properties.id]
    };
  }
  return feature;
};

export const getUpdatedParentRegionsWithIndicatorPressure = (regions: Dictionary<Region>, updatedChildRegions: Region[]): Region[] => {
  const regionUpdatesIds = new Set(updatedChildRegions.map(update => update.id));

  const regionGeometryPropertiesMap = createDictionaryFromList(
    updatedChildRegions,
    'id',
    region => (region.geometry as GeoJSON.Feature)?.properties
  );

  return getNonAreaRegions(regions).map((region): Region => {
    const geometries = isFeatureCollection(region.geometry)
      ? {
          ...region.geometry,
          features: (region.geometry as GeoJSON.FeatureCollection).features.map(
            (feature): GeoJSON.Feature => updateFeatureProperties({ feature, regionUpdatesIds, regionGeometryPropertiesMap })
          )
        }
      : region.geometry;
    return {
      ...region,
      geometry: geometries
    };
  });
};

export const getUpdatedChildRegionsWithIndicatorPressure = (
  regions: Dictionary<Region>,
  indicatorPressureResultMap: Dictionary<IndicatorPressureResponse>,
  useNewMapColors = false
): Region[] => {
  return Object.values(regions)
    .filter(region => !isFeatureCollection(region.geometry))
    .map(oldRegion => {
      const indicatorResult = indicatorPressureResultMap[oldRegion.id];
      const index = !indicatorResult?.indexes.length ? undefined : indicatorResult.indexes[0];
      const oldGeometry: GeoJSON.Feature = oldRegion.geometry as GeoJSON.Feature;

      let indicatorPressureColor;
      let indicator_pressure;
      if (index?.severity) {
        indicatorPressureColor = getSeverityColorFromSeverity(index.severity, useNewMapColors);
      }
      if (typeof index?.result === 'number') {
        indicator_pressure = `${index?.result?.toFixed(index?.indicator?.decimal_places)} ${index.indicator.uom}`;
      }

      const newRegion: Region = oldRegion;

      if (newRegion?.geometry) {
        newRegion.geometry = {
          ...oldGeometry,
          properties: {
            ...(oldGeometry.properties as GeometryProperties),
            indicatorPressureColor,
            indicator_pressure
          }
        };
      } else {
        newRegion.geometry = undefined;
      }

      return newRegion;
    });
};

export const buildFieldsByParentRegionId = (fields: Field[]): Dictionary<Field[]> => {
  const fieldsByParentRegion = _.groupBy(fields, 'parent_region_id');

  const parentRegionIds = Object.keys(fieldsByParentRegion);
  if (parentRegionIds.length === 2 && parentRegionIds.includes('null')) {
    const parentId = parentRegionIds.find(id => id !== 'null');

    if (parentId) {
      const regionsWithoutParentRegionIdRefactored = fieldsByParentRegion.null.map(region => ({ ...region, parent_region_id: parentId }));

      fieldsByParentRegion[parentId].push(...regionsWithoutParentRegionIdRefactored);

      delete fieldsByParentRegion.null;
    }
  }

  return fieldsByParentRegion;
};

/**
 * Build RegionTree. The return is similar to the return of endpoint v1/panel/properties/{property_id}/regions/{region_id}.
 * Use the fields, regions, seasons and crops to build a region tree. Is needed a root region.
 * @param abstractRegions {V1Region[]} Abstract regions. Fields are not included here.
 * @param seasonFields {SeasonField[]} Relationship of Season-Field.
 * @param fields {Field[]} Fields definitions.
 * @param seasons {SeasonFlat[]} Seasons.
 * @param crops {Crop[]} Crops of Seasons.
 * @param varieties {Variety[]} Varieties of fields.
 */
export const buildDeepRegion = (
  abstractRegions: V1Region[],
  seasonFields: FieldSeason[],
  fields: Field[],
  seasons: Season[],
  crops: Crop[] = [],
  varieties: Variety[] = []
): Region => {
  let rootRegionOptional: V1Region | undefined;

  const childRegionsByParentRegion = abstractRegions.reduce((accumulator, region) => {
    const { parentId } = region;

    if (!parentId) {
      rootRegionOptional = region;
      return accumulator;
    }
    return { ...accumulator, [parentId]: [...(accumulator[parentId] || []), buildRegionFromV1Region(region)] };
  }, {} as ListMultimap<Region>);

  if (!rootRegionOptional) {
    throw Error('Missing a root region!');
  }

  const rootRegion: Region = buildRegionFromV1Region(rootRegionOptional);
  const seasonFieldsById: Dictionary<FieldSeason> = _.mapKeys(seasonFields, 'areaId');
  const varietiesById = _.mapKeys(varieties, 'id');
  const cropsById = _.mapKeys(crops, 'id');
  const fieldsByParentRegion: ListMultimap<Field> = buildFieldsByParentRegionId(fields);

  return buildRegion(rootRegion, fieldsByParentRegion, childRegionsByParentRegion, seasonFieldsById, seasons, cropsById, varietiesById);
};

export const getFormattedDate = (date?: string): string | undefined => {
  if (!date) return '';

  return moment(date).format('L');
};

/** @deprecate Use buildAreaInfoBySeasonField instead */
export const buildAreaInfo = (
  region: Region,
  regionChildrenMapping: Record<string, Region[]>,
  areaSeasonAreaMap: Record<string, SeasonArea>
): AreaInfo => {
  const children: Region[] | undefined = regionChildrenMapping[region.id];

  if (!children) {
    const seasonArea = areaSeasonAreaMap[region.id];

    if (seasonArea) {
      return {
        area: seasonArea.areaInHectares,
        children: undefined,
        key: seasonArea.seasonAreaId,
        name: seasonArea.nameArea,
        areaVariables: [],
        crop: seasonArea.cropName,
        emergency_date: getFormattedDate(seasonArea.emergencyDate),
        harvest_date: getFormattedDate(seasonArea.harvestingDate),
        planting_date: getFormattedDate(seasonArea.plantingDate),
        variety: seasonArea.varieties.map(el => el.name).join()
      };
    }
  }

  return {
    area: 0,
    key: region.id,
    name: region.name,
    children: children ? children.map(childRegion => buildAreaInfo(childRegion, regionChildrenMapping, areaSeasonAreaMap)) : []
  };
};

export const getCropNameFromSeasonFieldBySeasons = (seasonId?: string, seasons?: Dictionary<Season>) => {
  if (!seasonId || !seasons || isEmpty(seasons)) return;

  return seasons[seasonId]?.crop.name;
};

const getVarietiesNamesFromSeasonFieldByRegionSeasons = ({
  seasonField,
  varieties,
  seasons
}: GetVarietiesNamesFromSeasonFieldByRegionSeasonsParams) => {
  if (!seasonField || !seasons || isEmpty(seasons) || !varieties) return;

  const seasonFromSeasonField = !!seasonField.season_id && seasons[seasonField.season_id];
  const varietiesIds = seasonField.varieties_ids;

  if (!varietiesIds?.length || !seasonFromSeasonField) return;

  const cropId = seasonFromSeasonField.crop.id;

  const varietiesNames = varietiesIds.reduce((acc, varietyId) => {
    const cropName = varieties[cropId]?.find(variety => variety.id === varietyId)?.name;

    if (cropName) {
      acc.push(cropName);
    }

    return acc;
  }, [] as string[]);

  return varietiesNames.join(', ');
};

export const buildAreaInfoBySeasonField = ({
  regionChildrenMapping,
  areaSeasonFieldMap,
  varieties,
  seasons,
  region
}: BuildAreaInfoBySeasonFieldProps): AreaInfo | undefined => {
  if (!region || !regionChildrenMapping || !areaSeasonFieldMap) return;

  const children = regionChildrenMapping?.[region.id];

  if (!children) {
    const seasonField = areaSeasonFieldMap[region.id];

    if (seasonField) {
      return {
        variety: getVarietiesNamesFromSeasonFieldByRegionSeasons({ seasonField, seasons, varieties }),
        crop: getCropNameFromSeasonFieldBySeasons(seasonField.season_id, seasons),
        emergency_date: seasonField.emergency_date,
        harvest_date: seasonField.harvesting_date,
        planting_date: seasonField.planting_date,
        area: region.total_area,
        children: undefined,
        key: seasonField.id,
        name: region.name,
        areaVariables: []
      };
    }
  }

  return {
    area: 0,
    key: region.id,
    name: region.name,
    children: children?.length
      ? children.map(childRegion =>
          buildAreaInfoBySeasonField({
            regionChildrenMapping,
            region: childRegion,
            areaSeasonFieldMap,
            varieties,
            seasons
          })
        )
      : undefined
  };
};

export const castToFourDecimalPlaces = (areaInfo: AreaInfo): AreaInfo => {
  const children = areaInfo.children;

  const childrenWithFourDecimalPlaces = children?.map(castToFourDecimalPlaces);
  const areaWithFourDecimalPlaces = +areaInfo.area.toFixed(4);

  return { ...areaInfo, children: childrenWithFourDecimalPlaces, area: areaWithFourDecimalPlaces };
};

export const getRegionChildrenMapping = (regions: Dictionary<Region>): Record<string, Region[]> => {
  const regionChildrenMapping: Record<string, Region[]> = {};
  Object.values(regions).forEach(region => {
    if (region?.parent_id) {
      if (regionChildrenMapping?.[region.parent_id]) {
        regionChildrenMapping[region.parent_id].push(region);
      } else {
        regionChildrenMapping[region.parent_id] = [region];
      }
    }
  });

  return regionChildrenMapping;
};

export const rootRegionWithChildren = (regions: Dictionary<Region>, rootRegionId: UUID): Region => {
  const recursiveRegionWithChildren = (regionId: UUID): Region => {
    const currentRegion = regions[regionId];
    if (!currentRegion?.children) {
      return currentRegion;
    }
    const children = (currentRegion?.children as string[]).map((childRegion): Region => recursiveRegionWithChildren(childRegion));
    return {
      ...currentRegion,
      children
    };
  };

  return recursiveRegionWithChildren(rootRegionId);
};

export const fillAreaValues = (areaInfo: AreaInfo): AreaInfo => {
  const children = areaInfo.children;
  if (!children) return areaInfo;

  const childrenWithFilledValues = children.map(child => fillAreaValues(child));
  const totalArea = childrenWithFilledValues.map(child => child.area).reduce<number>((a, b) => a + b, 0) ?? 0;

  return { ...areaInfo, children: childrenWithFilledValues, area: totalArea };
};

const buildRegionFromV1Region = (v1Region: V1Region): Region => {
  return {
    children: [],
    id: v1Region.id,
    name: v1Region.name,
    parent_id: v1Region.parentId,
    seasons: [],
    type: RegionType.REGION,
    total_area: 0
  };
};

const buildRegionAreaFromField = (
  field: Field,
  seasonField: FieldSeason,
  seasons: (Season | SeasonFlat)[],
  cropsById: Dictionary<Crop>,
  varietiesById: Dictionary<Variety>
) => {
  if (seasonField) {
    const fieldSeasons = seasons.reduce((accumulator, season) => {
      if (season.id !== seasonField.seasonId) {
        return accumulator;
      }

      return [
        ...accumulator,
        {
          ...season,
          crop: (season as Season)?.crop || cropsById[(season as Season).crop?.id],
          crop_id: (season as Season).crop?.id
        }
      ];
    }, [] as (Season | SeasonFlat)[]);

    const varietiesIds = seasonField?.varieties || [];
    return {
      geometry: { geometry: field.geometry, type: 'Feature', id: hashNumber(field.id), properties: { name: field.name, id: field.id } },
      id: field.id,
      name: field.name,
      parent_id: field.parent_region_id,
      seasons: fieldSeasons,
      type: RegionType.AREA,
      children: null,
      total_area: field.declared_area,
      varieties: varietiesIds.reduce((accumulator, varietyId) => {
        if (!varietiesById[varietyId]) {
          return accumulator;
        }
        return [...accumulator, varietiesById[varietyId]];
      }, [] as Variety[]),
      json_extended_attributes: field.json_extended_attributes
    };
  }
  throw Error('There is not a season field for this field!');
};

export const getDateEditPayloadFromEditField = (
  field: SeasonAreaUpdateType,
  formattedDate: string,
  seasonAreas: UUID[]
): UpdateSeasonAreasMassivelyPayload => {
  return {
    model: {
      varieties: field === SeasonAreaUpdateType.VARIETIES ? [] : undefined,
      emergencyDate: field === SeasonAreaUpdateType.EMERGENCY_DATE ? formattedDate : undefined,
      harvestingDate: field === SeasonAreaUpdateType.HARVESTING_DATE ? formattedDate : undefined,
      plantingDate: field === SeasonAreaUpdateType.PLANTING_DATE ? formattedDate : undefined
    },
    seasonIds: seasonAreas,
    fieldToUpdate: field
  };
};

/**
 * Get the fields from region tree recursively. The children must be normalized.
 * @param rootRegion Parent region
 * @param regions Dict of regions to iterate
 * @returns childrenIds
 */
const getFieldsIdsRecursive = (rootRegion: Region, regions: Dictionary<Region>): UUID[] => {
  const childrenIds = (rootRegion?.children as UUID[]) || [];
  const children = childrenIds.map((childId: UUID) => regions[childId]).filter(r => !!r);
  return [
    ...new Set([
      ...children.filter(child => child.type === RegionType.AREA).map(c => c.id),
      ...children.filter(child => child.type === RegionType.REGION).flatMap(region => getFieldsIdsRecursive(region, regions))
    ])
  ];
};

export const getFieldsRecursive = (rootRegion: Region): Region[] => {
  const children = (rootRegion?.children as Region[]) || [];
  return [
    ...new Set([
      ...children.filter(child => child.type === RegionType.AREA).map(c => c),
      ...children.filter(child => child.type === RegionType.REGION).flatMap(region => getFieldsRecursive(region))
    ])
  ];
};

const getFieldsIdsChildrens = (rootRegion: Nullable<Region>, regions: Dictionary<Region>): UUID[] => {
  if (!rootRegion) return [];
  const childrenIds = (rootRegion.children as UUID[]) || [];
  const children = childrenIds.map((childId: UUID) => regions[childId]).filter(r => !!r);
  return [...children.filter(child => child.type === RegionType.AREA).map(c => c.id)];
};

const hasGeometry = (feature: GeoJsonObject): boolean => {
  if (feature.type === 'FeatureCollection') {
    return (
      (feature as GeoJSON.FeatureCollection).features.filter((childFeature: GeoJsonObject) => {
        return hasGeometry(childFeature);
      }).length > 0
    );
  }

  const areaGeometry = feature as GeoJSON.Feature<Polygon>;
  if (areaGeometry && areaGeometry.geometry && areaGeometry.geometry.coordinates?.length) {
    return true;
  }
  return false;
};

export const getFieldIdsByEndDateInSeasonField = (fieldSeasons: FieldSeason[]) => {
  return fieldSeasons.reduce<Record<string, string[]>>((accumulator, seasonField) => {
    const mutableAccumulator = accumulator;
    const seasonFieldEndDate = moment(seasonField.endsAt);
    let referenceDate;

    if (seasonFieldEndDate.isAfter()) {
      referenceDate = moment().format('YYYY-MM-DD');
    } else {
      referenceDate = seasonFieldEndDate.format('YYYY-MM-DD');
    }

    if (!mutableAccumulator[referenceDate]) {
      mutableAccumulator[referenceDate] = [];
    }

    mutableAccumulator[referenceDate].push(seasonField.areaId);

    return mutableAccumulator;
  }, {});
};

export const fieldHasGeometry = (
  feature: GeoJsonObject,
  flags: Dictionary<boolean | string> | null = null,
  source: AreaWithoutGeometryFeatures | null = null
): boolean => {
  if (!source || (flags && flags.AreasWithoutGeometryFlags.toString().indexOf(source) >= 0)) {
    return hasGeometry(feature);
  }

  return true;
};

const regionHasGeometry = (region: Region) => {
  if (region.type === RegionType.AREA) {
    if (region.geometry) {
      return hasGeometry(region.geometry as any);
    }
  }
  return true;
};

export const getFieldsWithGeometryArray = (
  regions: Region[],
  flags: Dictionary<boolean | string> | null = null,
  source: AreaWithoutGeometryFeatures | null = null
): Region[] => {
  if (!source || (flags && flags.AreasWithoutGeometryFlags?.toString().indexOf(source) >= 0)) {
    return _.chain(regions)
      .filter(region => regionHasGeometry(region))
      .value();
  }

  return regions;
};

export const getFieldsWithGeometry = (
  regions: Dictionary<Region>,
  flags: Dictionary<boolean | string> | null = null,
  source: AreaWithoutGeometryFeatures | null = null
): Dictionary<Region> => {
  if (!source || (flags && flags.AreasWithoutGeometryFlags?.toString().indexOf(source) >= 0)) {
    const result = _.chain(getFieldsWithGeometryArray(Object.values(regions)))
      .keyBy('id')
      .value();
    return result;
  }

  return regions;
};

const toDeepRegion = (normalizedRegion: Region, currentInfoMap: Dictionary<CurrentInfo>, regions: Dictionary<Region>) => {
  return {
    ...normalizedRegion,
    children: (normalizedRegion.children as string[]).map(fieldId => ({
      ...regions[fieldId],
      current_info: currentInfoMap[fieldId] || regions[fieldId]?.current_info
    }))
  };
};

const getDAEByEmergenceDay = (emergenceDay: string | undefined) => {
  if (emergenceDay) {
    return moment().startOf('day').diff(moment(emergenceDay).startOf('day'), 'days');
  }
  return undefined;
};

const RegionsUtil = {
  buildDeepRegion,
  getFieldsIdsRecursive,
  getFieldsIdsChildrens,
  toDeepRegion,
  getFieldsRecursive,
  getDAEByEmergenceDay
};

export default RegionsUtil;
