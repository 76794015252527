import axios from 'axios';
import axiosObservable from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PROTECTOR_API_URL } from 'config/constants';
import type { DateISOString, UUID } from 'core/utils/basic.models';
import { map } from 'rxjs/operators';
import type { TaskPayload, TaskRegistrationCreate } from '../../pages/tasks/task-create/tasks-create.models';
import type { TaskDetailsResponse, TaskPage } from './task.models';

const protectorApiUrl = PROTECTOR_API_URL ?? 'http://localhost:8080';
const tasksUrl = `${protectorApiUrl}`;

export const getTaskPage = (
  propertyId: UUID,
  regions?: UUID[],
  starting_from?: DateISOString,
  ending_until?: DateISOString,
  page = 0,
  type?: string,
  status?: string,
  size = 10,
  search_str?: string,
  seasonId?: string,
  areas?: UUID[]
): AxiosObservable<TaskPage> => {
  return axiosObservable.post<TaskPage>(`${tasksUrl}/v1/activities/search?sort=startAt,desc&size=${size}&page=${page}`, {
    property_id: propertyId,
    regions,
    starting_from,
    ending_until,
    type,
    status,
    search_str,
    season_id: seasonId,
    areas
  });
};

export const getTask = (taskId: UUID, includeProducts?: boolean) => {
  return axiosObservable
    .get(`${tasksUrl}/v1/activities/${taskId}${includeProducts ? '?include_applied_products=true' : ''}`)
    .pipe(map(response => response.data));
};

export const createTask = (task: TaskPayload) => {
  return axiosObservable.post(`${tasksUrl}/v1/activities`, task);
};

export const editTask = (task: TaskPayload) => {
  return axiosObservable.put(`${tasksUrl}/v1/activities`, task);
};

export const createSpray = (spray: TaskRegistrationCreate) => {
  return axiosObservable.post(`${tasksUrl}/v1/sprays`, spray);
};

export const editSpray = (spray: TaskRegistrationCreate) => {
  return axiosObservable.put(`${tasksUrl}/v1/sprays/${spray.id}`, spray);
};

export const deleteTask = (taskId: UUID) => {
  return axios.delete(`${tasksUrl}/v1/activities/${taskId}`);
};

export const deleteApplication = (taskId: UUID, propertyId: UUID) => {
  return axios.delete(`${tasksUrl}/v1/sprays/${taskId}?property=${propertyId}`);
};

export const updateTask = (task: TaskPayload) => {
  return axiosObservable.put(`${tasksUrl}/v1/activities`, task);
};

export const getTaskDetails = (taskId: UUID, propertyId: UUID): AxiosObservable<TaskDetailsResponse> => {
  return axiosObservable.get<TaskDetailsResponse>(`${tasksUrl}/v1/properties/${propertyId}/activities?spray_id=${taskId}`);
};
