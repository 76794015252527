import isEmpty from 'lodash/isEmpty';
import type { EventType } from 'pages/timeline/timeline.models';
import { FILE_TYPE } from 'pages/timeline/timeline.models';

import phenologicalStateImagePhoto from '../markers/clusters/Phenological-state-photo.png';
import phenologicalStateImageSimulatedPhoto from '../markers/clusters/Phenological-state-simulated-photo.png';
import phenologicalStateImageSimulated from '../markers/clusters/Phenological-state-simulated.png';
import phenologicalStateImage from '../markers/clusters/Phenological-state.png';

import annotationImagePhoto from '../markers/clusters/Annotation-photo.png';
import annotationImageSimulatedPhoto from '../markers/clusters/Annotation-simulated-photo.png';
import annotationImageSimulated from '../markers/clusters/Annotation-simulated.png';
import annotationImage from '../markers/clusters/Annotation.png';

import monitoringImageMultiplePhoto from '../markers/Monitoring-multiple-photo.png';
import monitoringImagePhoto from '../markers/Monitoring-photo.png';
import monitoringImageSimulatedMultiplePhoto from '../markers/Monitoring-simulated-multiple-photo.png';
import monitoringImageSimulatedPhoto from '../markers/Monitoring-simulated-photo.png';
import monitoringImageSimulated from '../markers/Monitoring-simulated.png';
import monitoringImage from '../markers/Monitoring.png';
import monitoringImageSamplingSimulated from '../markers/Sampling/Sampling-simulated.png';
import monitoringImageSampling from '../markers/Sampling/Sampling.png';

import { DEFAULT_UUID } from 'core/shared/map/map.functions';
import monitoringImageSamplingPhoto from '../markers/Sampling/Sampling-photo.png';
import monitoringImageSamplingSimulatedPhoto from '../markers/clusters/Sampling-simulated-photo.png';
import note from '../markers/notes/clusters/Note.png';
import noteAudio from '../markers/notes/clusters/NoteAudio.png';
import notePhoto from '../markers/notes/clusters/NotePhoto.png';
import notePhotoAudio from '../markers/notes/clusters/NotePhotoAudio.png';
import noteSimulated from '../markers/notes/clusters/NoteSimulated.png';
import noteSimulatedAudio from '../markers/notes/clusters/NoteSimulatedAudio.png';
import noteSimulatedPhoto from '../markers/notes/clusters/NoteSimulatedPhoto.png';
import noteSimulatedPhotoAudio from '../markers/notes/clusters/NoteSimulatedPhotoAudio.png';
import type { Popup } from './sd-popup.models';

export const getMonitoringIcon = (
  simulated: boolean | undefined,
  hasMedia: boolean | string,
  hasMultiplePhotos: boolean | 0 | undefined
) => {
  if (hasMultiplePhotos) {
    return simulated ? monitoringImageSimulatedMultiplePhoto : monitoringImageMultiplePhoto;
  }
  if (hasMedia) {
    return simulated ? monitoringImageSimulatedPhoto : monitoringImagePhoto;
  }
  return simulated ? monitoringImageSimulated : monitoringImage;
};

export const getSamplingIcon = (simulated: boolean | undefined, hasMedia: boolean | string) => {
  if (hasMedia) {
    return simulated ? monitoringImageSamplingSimulatedPhoto : monitoringImageSamplingPhoto;
  }
  return simulated ? monitoringImageSamplingSimulated : monitoringImageSampling;
};

export const getNotesIcon = ({ isSimulated, hasPhoto, isSingleOrMultiplePhotos, enableMultiplePhotos, hasAudio }): string => {
  if (enableMultiplePhotos) {
    if (isSimulated) {
      if (!isSingleOrMultiplePhotos) {
        if (hasAudio) {
          return noteSimulatedAudio;
        }

        return noteSimulated;
      }

      if (hasAudio) {
        return noteSimulatedPhotoAudio;
      }

      return noteSimulatedPhoto;
    }

    if (isSingleOrMultiplePhotos) {
      if (hasAudio) {
        return notePhotoAudio;
      }

      return notePhoto;
    }

    if (hasAudio) {
      return noteAudio;
    }

    return note;
  }

  if (hasPhoto) {
    return isSimulated ? annotationImageSimulatedPhoto : annotationImagePhoto;
  }

  return isSimulated ? annotationImageSimulated : annotationImage;
};

export const getPhenologyIcon = (isSimulated: boolean | undefined, hasMedia: boolean | string): string => {
  if (hasMedia) {
    return isSimulated ? phenologicalStateImageSimulatedPhoto : phenologicalStateImagePhoto;
  }
  return isSimulated ? phenologicalStateImageSimulated : phenologicalStateImage;
};

export const selectMarkerImg = (
  marker: Popup<EventType.MONITORING> | Popup<EventType.PHENOLOGY> | Popup<EventType.ANNOTATION> | undefined,
  enableMultiplePhotos: boolean | undefined
) => {
  if (!marker?.popupProps) return undefined;

  const hasMultiplePhotos =
    (marker.markerProps.type !== 'ANNOTATION' && marker.popupProps.medias && marker.popupProps.medias.length > 1) ??
    (marker.popupProps.medias?.length &&
      marker.popupProps.medias.filter((media: { type: FILE_TYPE }) => media.type === FILE_TYPE.PICTURES)?.length > 1);

  const hasAudio = marker.popupProps.medias?.length && marker.popupProps.medias.some(media => media.type === FILE_TYPE.AUDIO);
  const hasImage = marker.popupProps.image && !isEmpty(marker.popupProps.image);

  const hasMedia = marker.popupProps.medias && marker.popupProps.medias.length > 0 ? true : marker.popupProps.image;
  const isSimulated = marker.markerProps.status?.includes('simulated');

  const isSampling =
    ('sampling_id' in marker.popupProps &&
      marker.popupProps.sampling_id &&
      marker.popupProps.sampling_id.length > 0 &&
      marker.popupProps.sampling_id !== DEFAULT_UUID) ??
    false;

  return {
    MONITORING: isSampling ? getSamplingIcon(isSimulated, hasMedia) : getMonitoringIcon(isSimulated, hasMedia, hasMultiplePhotos),
    PHENOLOGY: getPhenologyIcon(isSimulated, hasMedia),
    ANNOTATION: getNotesIcon({
      isSimulated: isSimulated,
      isSingleOrMultiplePhotos: hasImage || hasMultiplePhotos,
      enableMultiplePhotos,
      hasPhoto: !hasMedia,
      hasAudio
    })
  }[marker.markerProps.type];
};
