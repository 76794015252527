import type { CurrentSeasonArea } from 'entities/property/property.models';
import type { Region } from 'entities/region/region.models';
import { forEach } from 'lodash';
import moment from 'moment';
import type { SeasonField } from 'querys/season-field/season-field.models';
import type { Season } from './season.models';

export const isActive = (season: Season): boolean => !!(season?.end_date && moment().isBefore(moment(season.end_date)));

export const filterSeasonsActive = (seasons: Season[]): Season[] => seasons.filter(season => isActive(season));

export const extractActiveSeasonsIds = (seasons: Season[]): string[] =>
  seasons.reduce((accumulator, season) => {
    if (!isActive(season)) {
      return accumulator;
    }
    return [...accumulator, season.id];
  }, [] as string[]);

export const getDaeByEmergenceDay = (region?: Region, seasonAreas?: CurrentSeasonArea[], flag?: boolean | string | null): number => {
  const DAE = moment().diff(region?.current_info?.emergence_day, 'days');
  const validationDAE = DAE >= 0 ? DAE : 0;

  if (!flag) return validationDAE;

  if (region?.current_info?.harvest_day && moment(region?.current_info?.harvest_day).isBefore(moment())) {
    return moment(region?.current_info.harvest_day).diff(region?.current_info.emergence_day, 'days');
  }

  const areaEndAt = seasonAreas?.find(seasonArea => seasonArea.areaId === region?.id)?.endsAt;
  if (areaEndAt && moment(areaEndAt).isBefore(moment())) {
    return moment(areaEndAt).diff(region?.current_info?.emergence_day, 'days');
  }
  return validationDAE;
};

export const getDaeInSeaonArea = (seasonArea?: CurrentSeasonArea, seasonAreas?: CurrentSeasonArea[]): number => {
  const DAE = moment().diff(seasonArea?.emergencyDate, 'days');
  const validationDAE = DAE >= 0 ? DAE : 0;

  if (seasonArea?.harvestingDate && moment(seasonArea?.harvestingDate).isBefore(moment())) {
    return moment(seasonArea?.harvestingDate).diff(seasonArea?.emergencyDate, 'days');
  }

  const areaEndAt = seasonAreas?.find(currentSeasonArea => currentSeasonArea.areaId === seasonArea?.areaId)?.endsAt;
  if (areaEndAt && moment(areaEndAt).isBefore(moment())) {
    return moment(areaEndAt).diff(seasonArea?.emergencyDate, 'days');
  }
  return validationDAE;
};

export const getDAEInSeasonFieldV2 = (seasonField?: SeasonField): number => {
  if (!seasonField) return 0;

  const DAE = moment().diff(seasonField?.emergency_date, 'days');
  const validationDAE = DAE >= 0 ? DAE : 0;

  if (seasonField?.emergency_date && seasonField?.harvesting_date && moment(seasonField.harvesting_date).isBefore(moment())) {
    return moment(seasonField.harvesting_date).diff(seasonField.emergency_date, 'days');
  }

  const fieldEndDate = seasonField?.end_date;

  if (fieldEndDate && moment(fieldEndDate).isBefore(moment())) {
    return moment(fieldEndDate).diff(seasonField?.emergency_date, 'days');
  }
  return validationDAE;
};

export const getActiveSeasonsIds = (seasons?: Season[]): string[] => {
  const activeSeasonsIds: string[] = [];

  forEach(seasons, season => {
    if (isActive(season)) {
      activeSeasonsIds.push(season.id);
    }
  });

  return activeSeasonsIds;
};
