import type { InitCWElements } from '@cw-elements/config';
import { initCWElements, setHostAppId, setToken, setupAxiosInterceptor } from '@cw-elements/config';
import { initMfes } from '@cw-elements/mfe-setup/init';
import type { EventHint } from '@sentry/react';
import * as Sentry from '@sentry/react';
import type { ReactRouterInstrumentation } from '@sentry/react/types/types';
import { BrowserTracing } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import type { Locale } from 'antd/lib/locale-provider';
import localeDE from 'antd/lib/locale/de_DE';
import localeEN from 'antd/lib/locale/en_US';
import localeES from 'antd/lib/locale/es_ES';
import localeFR from 'antd/lib/locale/fr_FR';
import localeHU from 'antd/lib/locale/hu_HU';
import localeIT from 'antd/lib/locale/it_IT';
import localePL from 'antd/lib/locale/pl_PL';
import localeBR from 'antd/lib/locale/pt_BR';
import { C_LEVEL_REPORT } from 'authentication/contants';
import LoginChecker from 'authentication/login-checker.component';
import axios from 'axios';
import MainLayout from 'components/layout.component';
import { CROPWISE_ELEMENTS_MFE_URL, ENV_CW_COMPONENTS, PROTECTOR_APP_ID, SENTRY_URL, VITE_ENV } from 'config/constants';
import i18n from 'config/i18n';
import type { Dictionary } from 'config/types';
import { getCurrentUnitSystem, getPermissionFlags } from 'core/core.selectors';
import { LoadCurrentUserAccountService, LoadCurrentUserSuccess } from 'core/services/auth/auth.actions';
import type { User } from 'core/services/auth/auth.models';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import STTypo from 'core/shared/typo';
import useVersion from 'core/shared/version/useVersion.hook';
import { getCurrentLanguage, validatePlansAndEntitlements } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { validateUserAgainstAccessToken } from 'core/utils/user';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { getSelectedSeasonsIds } from 'entities/season/season.reducer';
import { isEmpty } from 'lodash';
import moment from 'moment';
import LoginOauth2 from 'pages/login/login.oauth2.container';
import { verifyNemadigitalConfigAvailability } from 'pages/nemadigital/nemadigital.selectors';
import { useGetRBACOperationsFullUser } from 'querys/rbac/rbac.query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';
import type { AppState } from 'redux/app-state';
import sentryService from 'sentry/service';

setHostAppId(PROTECTOR_APP_ID);

const OpportunityMachineContainer = React.lazy(() => import('pages/opportunity-machine/dashboard/opportunity-machine.container'));
const OpportunityMachineSetupContainer = React.lazy(() => import('pages/opportunity-machine/setup/opportunity-machine-setup.container'));
const HallOfCompanies = React.lazy(() => import('pages/hall-of-companies/hall-of-companies.container'));
const CLevelReportContainer = React.lazy(() => import('pages/c-level-report/c-level-report.container'));
const HallOfProperties = React.lazy(() => import('pages/dashboard/dashboard.container'));
const CompanyPresentation = React.lazy(() => import('pages/presentation-mode/presentation-mode.container'));
const CompanyWarehouse = React.lazy(() => import('pages/warehouse/company-warehouse.container'));
const Financial = React.lazy(() => import('pages/finance/finance.container'));
const PropertyDashboard = React.lazy(() => import('pages/dashboard-farm/dashboard-farm.container'));
const AreaInfo = React.lazy(() => import('pages/timeline/area-info/area-info'));
const Timeline = React.lazy(() => import('pages/timeline/timeline.page.container'));
const FixedPoints = React.lazy(() => import('pages/fixed-points/fixed-points.container'));
const StaticPoints = React.lazy(() => import('pages/static-points/static-points.container'));
const ScoutingPlan = React.lazy(() => import('pages/scouting-plan/scouting-plan.container'));
const ScoutingPlanVisualization = React.lazy(() => import('pages/scouting-plan-visualization/scouting-plan-visualization.container'));
const Tasks = React.lazy(() => import('pages/tasks/tasks.container'));
const TasksNewScouting = React.lazy(() => import('pages/tasks/scouting/scouting-create'));
const TasksNewSampling = React.lazy(() => import('pages/tasks/sampling/sampling-create'));
const TasksNewPrescription = React.lazy(() => import('pages/tasks/task-create/task-prescription.container'));
const SmartSpray = React.lazy(() => import('pages/smart-spray/smart-spray.container'));
const Integrations = React.lazy(() => import('pages/integrations/integrations.container'));
const NewSmartSprayNewPlan = React.lazy(() => import('pages/smart-spray/plan/plan.container'));
const CottonGrowth = React.lazy(() => import('pages/cotton/cotton.container'));
const DiseaseRisk = React.lazy(() => import('pages/disease-risk/disease-risk.container'));
const DiseaseRiskConfiguration = React.lazy(
  () => import('pages/disease-risk/disease-risk-configuration/disease-risk-configuration.container')
);
const DiseaseRiskCropCalendar = React.lazy(() => import('pages/disease-risk/disease-risk-crop-calendar/disease-risk-crop-calendar'));
const ScoutingScoreHallContainer = React.lazy(() => import('pages/scouting-score/scouting-score-hall.container'));
const ScoutingScoreCreateFormContainer = React.lazy(() => import('pages/scouting-score/scouting-score-create-form.container'));
const SugarCane = React.lazy(() => import('pages/sugarcane/sugarcane.container'));
const SprayRegistration = React.lazy(() => import('pages/tasks/task-create/spray-registration/spray-registration.container'));
const EditMethodology = React.lazy(() => import('pages/edit-methodology/pages/edit-methodology.container'));
const EditMethodologyList = React.lazy(() => import('pages/edit-methodology/pages/edit-methodology-list.container'));
const PowerBIReports = React.lazy(() => import('pages/powerbi-reports/powerbi-reports-container'));
const loadSmartPlan = () => import('smart-plan');
const SmartPlan = React.lazy(() => loadSmartPlan().then((module: { SmartPlan: React.FC }) => ({ default: module.SmartPlan })));
const IntegrationsPerfectFlightLinkingFarmComponent = React.lazy(
  () => import('pages/integrations/perfect-flight/integrations-perfect-flight-linking-farm-component')
);
const IntegrationsPerfectFlightLinkingArea = React.lazy(
  () => import('pages/integrations/perfect-flight/integrations-perfect-flight-linking-area.component')
);
const IntegrationsPerfectFlightContainer = React.lazy(
  () => import('pages/integrations/perfect-flight/integrations-perfect-flight-container')
);
const IntegrationsPerfectFlightInstall = React.lazy(() => import('pages/integrations/perfect-flight/integrations-perfect-flight-install'));
const IntegrationsJDOCLinkingProperty = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-linking-property.component'));
const CreateControlStategyContainer = React.lazy(
  () => import('pages/control-strategy/create-control-strategy/create-control-strategy.container')
);
const IntegrationsJDOCLinkingArea = React.lazy(() => import('pages/integrations/jdoc/integration-jdoc-linking-area.component'));
const IntegrationsJdocContainer = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-container'));
const IntegrationsJDOCInstall = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-install.component'));
const UnlicensedUserContainer = React.lazy(() => import('pages/login/login.unlicensed-user'));
const IntegrationsJDOCLogin = React.lazy(() => import('pages/integrations/jdoc/integrations-jdoc-login.component'));
const CompanyEmptyState = React.lazy(() => import('pages/hall-of-companies/hall-of-companies-empty-state'));
const IntegrationsTrapViewInstall = React.lazy(() => import('pages/integrations/trap-view/integrations-trapview-install'));
const IntegrationsSaa = React.lazy(() => import('pages/integrations/saa/integrations-saa'));
const RegionalOverviewContainer = React.lazy(() => import('pages/regional-overview/regional-overview-container'));
const NotesContainer = React.lazy(() => import('pages/notes'));
const RoutesConfiguration = React.lazy(() => import('pages/routes-configuration/routes-configuration.container'));
const PostHarvestMap = React.lazy(() => import('pages/post-harvest-map/post-harvest-map.container'));
const ScoutingPlanPage = React.lazy(() => import('pages/routes-configuration/scouting-plan-page/scouting-plan-page'));
const NemaDigitalConfig = React.lazy(() => import('pages/nemadigital/config/nemadigital-config.container'));
const TaskFactory = React.lazy(() => import('pages/tasks/ag-operations/task-factory'));
const FieldBookContainer = React.lazy(() => import('pages/field-book/field-book.container'));
const FieldClusteringConfiguration = React.lazy(() => import('pages/field-clustering/configuration/field-clustering-configuration'));
const FieldClusteringMapResult = React.lazy(() => import('pages/field-clustering/map-result/field-clustering-map-result'));

const FieldClusteringMapVisualization = React.lazy(
  () => import('pages/field-clustering/map-visualization/field-clustering-map-visualization')
);
const SetupIntegration = React.lazy(() => import('pages/integrations/rea/SetupIntegration'));
const ViewSyncedData = React.lazy(() => import('pages/integrations/rea/ViewSyncedData'));
const IntegrationsRea = React.lazy(() => import('pages/integrations/rea/integrations-rea'));

const redirect = <Navigate to='/hall-of-companies' />;

const AppRoutes = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const property = useSelector((state: AppState) => getSelectedProperty(state));
  const isLogged = useSelector((state: AppState) => state.uiState.auth.isLogged);
  const permissionFlags = useSelector(getPermissionFlags);
  const selectedSeasonsIds = useSelector(getSelectedSeasonsIds);
  const currentUnitSystem = useSelector(getCurrentUnitSystem);

  const licenseStatus = useSelector((state: AppState) => state.entities.company.licensingStatus);
  const nemaDigitalConfigAvailability = useSelector(verifyNemadigitalConfigAvailability);

  const { data: fullUserPermission } = useGetRBACOperationsFullUser(isLogged);

  const [locale, setLocale] = useState<Locale>(localeEN);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  let screen = useLocation().pathname.split('/').pop();

  const { pathname } = useLocation();

  if (pathname.includes(C_LEVEL_REPORT) && !isLogged) {
    sessionStorage.setItem(C_LEVEL_REPORT, pathname);
  }

  const segmentTracking = useSegmentTracking();

  useEffect(() => {
    if (!firstLoad || (screen !== company?.id && screen !== property?.id && screen !== 'hall-of-companies' && (!company || !property)))
      return;

    if (screen === company?.id) {
      screen = 'Hall of Properties';
    } else if (screen === property?.id) {
      screen = 'Property Dashboard';
    }

    segmentTracking.track(`Loaded Panel`, { screen });

    setFirstLoad(false);
  }, [company, property, screen]);

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();
    moment.locale(currentLanguage);

    switch (currentLanguage) {
      case 'pt-br':
        setLocale(localeBR);
        break;
      case 'de-de':
        setLocale(localeDE);
        break;
      case 'fr-fr':
      case 'fr':
        setLocale(localeFR);
        break;
      case 'pl-pl':
        setLocale(localePL);
        break;
      case 'es':
      case 'es-ar':
      case 'es-AR':
        setLocale(localeES);
        break;
      case 'hu-hu':
        setLocale(localeHU);
        break;
      case 'it':
        setLocale(localeIT);
        break;
      default:
        setLocale(localeEN);
        break;
    }
  }, [t]);

  const flags = useSelector<AppState, Dictionary<boolean | string> | null>(state => state.uiState.global.systemFlags);

  const routingInstrumentation = useRoutingInstrumentation() as ReactRouterInstrumentation;

  const initSentry = useCallback(
    (versionApp?: string) => {
      if (!Sentry?.getCurrentHub()?.getClient()) {
        const browserTracing = new BrowserTracing({
          routingInstrumentation
        });

        Sentry.init({
          dsn: SENTRY_URL,
          integrations: [browserTracing],
          tracesSampleRate: 0.25,
          environment: VITE_ENV,
          release: `protector4-user-panel@${versionApp?.length ? versionApp : 'staging'}`,
          enabled: window.location.hostname !== 'localhost',
          beforeSend: (event: Sentry.Event, hint: EventHint) => {
            // Temporary code for P40-37408
            if (hint.originalException != null) {
              event.extra = {
                ...event.extra,
                ...hint.originalException
              };
            }
            return event;
          }
        });
      }
    },
    [flags?.P40_40442_remove_hashfile, routingInstrumentation]
  );

  const version = useVersion();

  useEffect(() => {
    initSentry(version);
  }, [version, initSentry]);

  const token = localStorage.getItem('access_token');

  const setUserState = useCallback(
    (user: User) => {
      dispatch(LoadCurrentUserSuccess(user));
      validateUserAgainstAccessToken(user);
    },
    [dispatch]
  );

  const initializeCropwiseMFE = useCallback(async () => {
    await initMfes({
      'input-details': `${CROPWISE_ELEMENTS_MFE_URL}/input-details`
    });
  }, []);

  useEffect(() => {
    if (!fullUserPermission || !isLogged) return;
    const isUserAccountService = !isEmpty(fullUserPermission?.allowed_operations);
    dispatch(LoadCurrentUserAccountService(isUserAccountService));
  }, [dispatch, fullUserPermission, isLogged]);

  useEffect(() => {
    initCWElements({
      environment: ENV_CW_COMPONENTS,
      excludeAuthorities: true,
      getCurrentUserCallback: setUserState
    } as InitCWElements);

    void initializeCropwiseMFE();
    setupAxiosInterceptor(axios);
  }, [initializeCropwiseMFE, setUserState]);

  useEffect(() => {
    if (token) setToken(token);
  }, [token]);

  useEffect(() => {
    const information = { company, property, screen, segmentTracking };
    Sentry.addGlobalEventProcessor(event => sentryService.globalEventProcessor(event, information));
    Sentry.setTags({
      language: getCurrentLanguage(),
      screen,
      companyId: company?.id ?? '',
      companyName: company?.name ?? '',
      propertyId: property?.id ?? '',
      propertyName: property?.name ?? ''
    });
  }, [company, property, locale, screen]);

  const overrideLocale = (currentLocale: Locale): Locale => ({
    ...currentLocale,
    DatePicker: {
      ...currentLocale.DatePicker,
      lang: {
        ...currentLocale.DatePicker.lang,
        timeSelect: t('antd.date_picker.lang.time_select')
      }
    }
  });

  const validateNotes = useMemo(() => {
    const hasEntitlement = validatePlansAndEntitlements(licenseStatus, null, [Entitlements.NOTES_PAGE]);
    return !!(flags?.P40_24023_notes_module && hasEntitlement);
  }, [licenseStatus, flags]);

  return i18n?.isInitialized ? (
    <ConfigProvider locale={overrideLocale(locale)}>
      <Routes>
        {!isLogged && <Route path='*' element={<LoginChecker />} />}
        <Route path='authenticate' element={<LoginOauth2 />} />
        {isLogged && (
          <Route path='/' element={<MainLayout />}>
            <Route path='/no-companies' element={<CompanyEmptyState />} />
            <Route path='/unlicensed' element={<UnlicensedUserContainer />} />
            <Route
              path='/company/:companyId/smart-plan/*'
              element={
                <SmartPlan
                  config={{
                    unitSystem: currentUnitSystem,
                    org: company?.id,
                    token: localStorage.getItem('access_token'),
                    baseRoute: `/company/${company?.id}/smart-plan`,
                    environment: VITE_ENV,
                    cropCycle: selectedSeasonsIds,
                    locale: localStorage.getItem('i18nextLng')
                  }}
                />
              }
            />

            <Route
              path='/company/:companyId/property/:propertyId/smart-plan/*'
              element={
                <SmartPlan
                  key={`${property?.id}`}
                  config={{
                    unitSystem: currentUnitSystem,
                    org: company?.id,
                    token: localStorage.getItem('access_token'),
                    baseRoute: `/company/${company?.id}/property/${property?.id}/smart-plan`,
                    environment: VITE_ENV,
                    cropCycle: selectedSeasonsIds,
                    locale: localStorage.getItem('i18nextLng')
                  }}
                />
              }
            />
            <Route path='/hall-of-companies' element={<HallOfCompanies />} />
            <Route path='/company/:companyId/overview' element={<CLevelReportContainer />} />
            <Route path='/company/:companyId' element={<HallOfProperties />} />
            <Route path='/company/:companyId/presentation' element={<CompanyPresentation />} />
            <Route path='/company/:companyId/edit-methodology' element={<EditMethodologyList />} />
            <Route path='/company/:companyId/edit-methodology/:methodologyId' element={<EditMethodology />} />
            <Route
              path='/company/:companyId/warehouses'
              element={!permissionFlags?.hideWarehouse.value ? <CompanyWarehouse /> : redirect}
            />
            <Route path='/company/:companyId/regional-overview' element={<RegionalOverviewContainer />} />
            <Route path='/company/:companyId/reports' element={<PowerBIReports />} />
            <Route
              path='/company/:companyId/property/:propertyId'
              element={!permissionFlags?.hidePropertyDashboard.value ? <PropertyDashboard /> : redirect}
            />
            <Route
              path='/company/:companyId/property/:propertyId/warehouses'
              element={!permissionFlags?.hideWarehouse.value ? <CompanyWarehouse /> : redirect}
            />
            <Route
              path='/company/:companyId/property/:propertyId/financial'
              element={!permissionFlags?.hideFinancial.value ? <Financial /> : redirect}
            />
            <Route path='/company/:companyId/property/:propertyId/area-info' element={<AreaInfo />} />
            <Route path='/company/:companyId/property/:propertyId/timeline' element={<Timeline />} />
            <Route path='/company/:companyId/property/:propertyId/disease-risk' element={<Outlet />}>
              <Route path='' element={<DiseaseRisk />} />
              <Route path='configuration' element={<DiseaseRiskConfiguration />} />
              <Route path='crop-calendar' element={<DiseaseRiskCropCalendar />} />
            </Route>
            <Route path='/company/:companyId/property/:propertyId/fixed-points' element={<FixedPoints />} />
            <Route
              path='/company/:companyId/property/:propertyId/static-points'
              element={!permissionFlags?.hideTrapsManagement.value ? <StaticPoints /> : redirect}
            />
            <Route path='/company/:companyId/property/:propertyId/static-points/scouting-plan' element={<ScoutingPlan />} />
            <Route
              path='/company/:companyId/property/:propertyId/static-points/scouting-plan/map'
              element={<ScoutingPlanVisualization />}
            />
            <Route path='/company/:companyId/property/:propertyId/tasks' element={<Tasks />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/new-scouting' element={<TasksNewScouting />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/new-sampling' element={<TasksNewSampling />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/new-prescription' element={<TasksNewPrescription />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/spray-registration' element={<SprayRegistration />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/ag-operations/:taskType' element={<TaskFactory />} />
            <Route
              path='/company/:companyId/property/:propertyId/smart-spray'
              element={!permissionFlags?.hideSmartSpray.value ? <SmartSpray /> : redirect}
            />
            <Route
              path='/company/:companyId/property/:propertyId/smart-spray/new-plan'
              element={!permissionFlags?.hideSmartSpray.value ? <NewSmartSprayNewPlan /> : redirect}
            />
            <Route path='/company/:companyId/property/:propertyId/cotton' element={<CottonGrowth />} />
            <Route
              path='/company/:companyId/property/:propertyId/integrations'
              element={!permissionFlags?.hideIntegrations.value ? <Integrations /> : redirect}
            />
            <Route
              path='/company/:companyId/property/:propertyId/integrations/perfect-flight'
              element={!permissionFlags.hideIntegrations.value ? <IntegrationsPerfectFlightContainer /> : redirect}>
              <Route path='install' element={<IntegrationsPerfectFlightInstall />} />
              <Route path='linking-property' element={<IntegrationsPerfectFlightLinkingFarmComponent />} />
              <Route path='linking-areas' element={<IntegrationsPerfectFlightLinkingArea />} />
            </Route>
            <Route
              path='/company/:companyId/property/:propertyId/integrations/jdoc'
              element={!permissionFlags?.hideIntegrations.value ? <IntegrationsJdocContainer /> : redirect}>
              <Route path='install' element={<IntegrationsJDOCInstall />} />
              <Route path='login' element={<IntegrationsJDOCLogin />} />
              <Route path='linking-property' element={<IntegrationsJDOCLinkingProperty />} />
              <Route path='linking-areas/org/:orgId/farm/:farmId/' element={<IntegrationsJDOCLinkingArea />} />
            </Route>
            <Route path='/company/:companyId/property/:propertyId/integrations/trap-view' element={<IntegrationsTrapViewInstall />} />
            <Route path='/company/:companyId/property/:propertyId/integrations/saa' element={<IntegrationsSaa />} />
            <Route path='/company/:companyId/property/:propertyId/integrations/rea' element={<IntegrationsRea />} />
            <Route path='/company/:companyId/property/:propertyId/integrations/rea/view-synced-data' element={<ViewSyncedData />} />
            <Route path='/company/:companyId/property/:propertyId/integrations/rea/rea-integration-setup' element={<SetupIntegration />} />

            <Route path='/company/:companyId/property/:propertyId/borer-risk' element={<SugarCane />} />

            <Route path='/company/:companyId/property/:propertyId/borer-risk/new-strategy' element={<CreateControlStategyContainer />} />
            <Route path='/company/:companyId/property/:propertyId/reports' element={<PowerBIReports />} />
            <Route path='/company/:companyId/property/:propertyId/scouting-score' element={<ScoutingScoreHallContainer />} />
            <Route
              path='/company/:companyId/property/:propertyId/scouting-score/configuration'
              element={<ScoutingScoreCreateFormContainer />}>
              <Route path=':configurationId' element={<ScoutingScoreCreateFormContainer />} />
            </Route>

            <Route path='/company/:companyId/property/:propertyId/integration/:typeIntegration'>
              <Route path='disease-risk' element={<DiseaseRisk />} />
            </Route>

            <Route
              path='/company/:companyId/property/:propertyId/integration/:typeIntegration/disease-risk/configuration'
              element={<DiseaseRiskConfiguration />}
            />

            <Route path='/company/:companyId/field-book' element={<FieldBookContainer />} />

            <Route path='/company/:companyId/property/:propertyId/tasks/ScoutingPlanPage' element={<ScoutingPlanPage />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/routesConfiguration' element={<RoutesConfiguration />} />
            <Route path='/company/:companyId/property/:propertyId/tasks/postHarvest/map' element={<PostHarvestMap />} />

            <Route
              path='/company/:companyId/property/:propertyId/notes'
              element={<NotesContainer hasAccessNotes={validateNotes} redirectPath='/hall-of-companies' />}
            />

            <Route
              path='/company/:companyId/property/:propertyId/nemadigital'
              element={nemaDigitalConfigAvailability ? <NemaDigitalConfig /> : redirect}
            />

            <Route path='/company/:companyId/opportunity-machine' element={<OpportunityMachineContainer />} />
            <Route path='/company/:companyId/opportunity-machine/setup' element={<OpportunityMachineSetupContainer />} />

            <Route
              path='/company/:companyId/property/:propertyId/field-clustering/configuration'
              element={<FieldClusteringConfiguration />}
            />
            <Route path='/company/:companyId/property/:propertyId/field-clustering/map/' element={<FieldClusteringMapResult />} />
            <Route path='/company/:companyId/property/:propertyId/field-clustering' element={<FieldClusteringMapVisualization />} />
          </Route>
        )}
      </Routes>
    </ConfigProvider>
  ) : (
    <STTypo>&nbsp;</STTypo>
  );
};

export default AppRoutes;
