import { Divider } from 'antd';
import type { Dictionary } from 'config/types';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import { UnitSystem, formatArea, getCurrentUnitSystem, includesName, mapEntitiesByAttr } from 'core/utils/functions';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import type { Region } from 'entities/region/region.models';
import { RegionType } from 'entities/region/region.models';
import type { Season } from 'entities/season/season.models';
import { compact, get, includes, intersection, isEmpty, isEqual, isNil, keyBy, map, reduce } from 'lodash';
import { useMemo } from 'react';
import type { RegionTreeItem, RegionTreeType } from './region-tree-select.models';

export const sortAlphabeticallyByAttr = <T,>(arr: T[], attr: string): T[] => {
  if (!arr) return [];

  return arr.sort((a, b) => {
    if (a[attr] > b[attr]) return 1;
    if (b[attr] > a[attr]) return -1;
    return 0;
  });
};

export const isValidAreaInSelectedSeason = (regionSeasons: Season[], selectedSeasonsIds: string[]): boolean => {
  const regionSeasonsIds = mapEntitiesByAttr(regionSeasons, 'id');

  return intersection(regionSeasonsIds, selectedSeasonsIds).length > 0;
};

export const isAreaInActiveSeason = (
  regionId: string,
  currentSeasonAreasDict?: Dictionary<CurrentSeasonArea>,
  selectedSeasonsIds?: string[]
) => {
  const regionSeasonArea = currentSeasonAreasDict?.[regionId];

  return includes(selectedSeasonsIds, regionSeasonArea?.seasonId);
};

export const sumAreasChildren = (children: RegionTreeItem[]): number => reduce(children, (sum, child) => sum + (child?.totalArea ?? 0), 0);

export const parseTitleRegion = (region: Region, childrenTotalArea: number): JSX.Element => {
  const metric = formatArea(childrenTotalArea, 0);
  const currentUnitSystem = getCurrentUnitSystem();
  const metricUnit = currentUnitSystem === UnitSystem.IMPERIAL ? 'ac' : 'ha';
  const isArea = region?.type === RegionType.AREA;
  const currentSeasonArea = region?.seasons[0];
  return (
    <div className='sd-region-tree-select__tree__child'>
      {isArea && (
        <>
          <STTypo type={TypeOptions.H6}>{region?.name}</STTypo>

          <STTypo type={TypeOptions.C1}>
            {`${metric} ${metricUnit} • `}
            <STTypo type={TypeOptions.C1} uppercase>
              {currentSeasonArea?.name}
            </STTypo>
          </STTypo>
          <Divider />
        </>
      )}
      {!isArea && (
        <STTypo color={ColorOptions.WHITE} type={TypeOptions.H6}>
          {region?.name}
        </STTypo>
      )}
    </div>
  );
};

export const parseRegionsRecursively = (
  isFilterGeometry: boolean | undefined,
  regions: Dictionary<Region>,
  region: Region,
  selectedSeasonsIds: string[],
  type: RegionTreeType,
  parentId?: string | null,
  currentSeasonAreasDict?: Dictionary<CurrentSeasonArea>,
  filterByCurrentSeasonArea?: boolean
): RegionTreeItem | undefined => {
  const isChild = isEqual(region?.parent_id, parentId);
  const isRoot = isNil(region?.parent_id);
  const isRegionHasNoChildren = region?.type === RegionType.REGION && (isEmpty(region?.children) || isNil(region?.children));
  const isArea = region?.type === RegionType.AREA;
  const geometry = region?.geometry as any;

  if (isFilterGeometry && isArea && !geometry?.geometry?.coordinates?.length) {
    return undefined;
  }

  if ((isChild && !isRoot && !isValidAreaInSelectedSeason(region?.seasons, selectedSeasonsIds)) || isRegionHasNoChildren) {
    return undefined;
  }

  if (!isChild && !isRoot) {
    return undefined;
  }

  // Checks if the field is in an active season when currentSeasonAreas is defined
  if (
    isChild &&
    isArea &&
    filterByCurrentSeasonArea &&
    !isNil(currentSeasonAreasDict) &&
    !isAreaInActiveSeason(region?.id, currentSeasonAreasDict, selectedSeasonsIds)
  ) {
    return undefined;
  }

  const children =
    region?.children &&
    compact(
      map(region?.children as string[], (childId: string) =>
        parseRegionsRecursively(
          isFilterGeometry,
          regions,
          get(regions, childId),
          selectedSeasonsIds,
          type,
          region?.id,
          currentSeasonAreasDict,
          filterByCurrentSeasonArea
        )
      )
    );

  const isRegionHasNoChildrenWithActiveSeason =
    region?.type === RegionType.REGION && isEmpty(children) && filterByCurrentSeasonArea && !isNil(currentSeasonAreasDict);
  if (isRegionHasNoChildrenWithActiveSeason) {
    return undefined;
  }

  const sortedChildren = sortAlphabeticallyByAttr(children, 'name');
  const isLeaf = region?.type === RegionType.AREA;
  const sumTotalArea = isLeaf ? region?.total_area : sumAreasChildren(sortedChildren);

  return {
    key: region?.id,
    value: region?.id,
    name: region?.name,
    isLeaf,
    children,
    title: type === 'default' ? region?.name : parseTitleRegion(region, sumTotalArea),
    totalArea: sumTotalArea
  };
};

const MAX_AMOUNT_NODES = 10;
export const getTreeDefaultExpandedKeys = (
  defaultExpanded: boolean,
  regions: Dictionary<Region>,
  rootRegionId?: string
): string[] | undefined => {
  if (!defaultExpanded || !rootRegionId || isEmpty(regions)) {
    return undefined;
  }

  const children = regions?.[rootRegionId]?.children;
  const isGreaterMaxNodes = children?.length > MAX_AMOUNT_NODES;

  if (isNil(children)) {
    return undefined;
  }

  if (isEmpty(children) || isGreaterMaxNodes) {
    return [rootRegionId];
  }

  return children as string[];
};

export const isAValidItemForSearch = (search: string, treeNode: any, treeNodeFilterProp: string, regions: Dictionary<Region>): boolean => {
  const item: RegionTreeItem = treeNode.props;
  const filterByName = includesName(item[treeNodeFilterProp], search);
  let filterByParentName = false;

  const parentId = regions?.[treeNode.key]?.parent_id;

  if (parentId) {
    filterByParentName = includesName(regions[parentId].name, search);
  }

  return filterByName || filterByParentName;
};

const isRootWithoutChildren = (treeData: RegionTreeItem[]) => {
  const root = treeData?.[0];

  return isEmpty(root?.children) || isNil(root?.children);
};

export const useCreateTreeData = (
  type: 'default' | 'dark',
  regions?: Dictionary<Region>,
  rootRegionId?: string,
  selectedSeasonsIds?: string[],
  isFilterGeometry?: boolean,
  currentSeasonAreas?: CurrentSeasonArea[],
  filterByCurrentSeasonArea?: boolean
): RegionTreeItem[] => {
  const treeData = useMemo(() => {
    if (isNil(rootRegionId) || isNil(regions) || isEmpty(regions) || isNil(selectedSeasonsIds)) return [];

    const currentSeasonAreasDict = keyBy(currentSeasonAreas, 'areaId');
    const rootRegion = get(regions, rootRegionId);
    const parsedRegions = parseRegionsRecursively(
      isFilterGeometry,
      regions,
      rootRegion,
      selectedSeasonsIds,
      type,
      rootRegionId,
      currentSeasonAreasDict,
      filterByCurrentSeasonArea
    );

    return parsedRegions ? [parsedRegions] : [];
  }, [regions, rootRegionId, selectedSeasonsIds, currentSeasonAreas, filterByCurrentSeasonArea]);

  return isRootWithoutChildren(treeData) ? [] : treeData;
};
