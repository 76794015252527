import type { ISTMarkerProps } from 'core/shared/map/map.models';
import type { Action, DateISOString, UUID } from '../../core/utils/basic.models';
import type { LoadTasksSuccessType, TaskType } from './task.models';

export const TasksActionTypes = {
  LOAD_TASKS_REQUEST: '[Tasks] Load Tasks Request',
  LOAD_TASKS_SUCCESS: '[Tasks] Load Tasks Success',
  LOAD_TASKS_FAILURE: '[Tasks] Load Tasks Failure',
  CREATE_TASK_FAILURE: '[Tasks] Create Task Failure',
  DELETE_LOCAL_TASK: '[Tasks] Delete Local Task',
  SET_MARKERS: '[Tasks] Set Markers',
  CLEAR_MARKERS: '[Tasks] Set Clear Markers'
};

export const LoadTasksRequest = (
  propertyId: string,
  starting_from: DateISOString,
  ending_until: DateISOString,
  regions?: string[],
  page = 0,
  type?: TaskType,
  status?: string,
  search_str?: string,
  size?: number,
  seasonId?: string,
  areas?: string[]
) => {
  return {
    type: TasksActionTypes.LOAD_TASKS_REQUEST,
    payload: { propertyId, regions, starting_from, ending_until, page, type, status, search_str, size, seasonId, areas }
  };
};

export const DeleteLocalTask = (taskId: UUID) => {
  return {
    type: TasksActionTypes.DELETE_LOCAL_TASK,
    payload: taskId
  };
};

export const SetMarkers = (id: string, markers: ISTMarkerProps[]) => {
  return {
    type: TasksActionTypes.SET_MARKERS,
    payload: { id, markers }
  };
};

export const ClearMarkers = () => {
  return {
    type: TasksActionTypes.CLEAR_MARKERS
  };
};

export const LoadTasksSuccess: LoadTasksSuccessType = taskPage => {
  return {
    type: TasksActionTypes.LOAD_TASKS_SUCCESS,
    payload: taskPage
  };
};

export const LoadTasksFailure = (error: string): Action<string> => {
  return {
    type: TasksActionTypes.LOAD_TASKS_FAILURE,
    payload: error
  };
};
