import { type ThemeConfig } from 'antdV5';
import { COLOR_OPTIONS, FONT_FAMILY, TYPOGRAPHY_LEVEL } from 'config/style';

export { default as localeDE } from 'antdV5/lib/locale/de_DE';
export { default as localeEN } from 'antdV5/lib/locale/en_US';
export { default as localeES } from 'antdV5/lib/locale/es_ES';
export { default as localeFR } from 'antdV5/lib/locale/fr_FR';
export { default as localeHU } from 'antdV5/lib/locale/hu_HU';
export { default as localeIT } from 'antdV5/lib/locale/it_IT';
export { default as localePL } from 'antdV5/lib/locale/pl_PL';
export { default as localeBR } from 'antdV5/lib/locale/pt_BR';

export const PREFIX_CLS = 'ant';

export enum LOCALE_CODES {
  pt_br = 'pt-br',
  de_de = 'de-de',
  fr_fr = 'fr-fr',
  fr = 'fr',
  pl_pl = 'pl-pl',
  es = 'es',
  es_ar = 'es-ar',
  es_AR = 'es-AR',
  hu_hu = 'hu-hu',
  it = 'it',
  en = 'en'
}

export const THEME: ThemeConfig = {
  token: {
    colorPrimary: COLOR_OPTIONS.GREEN_50,
    colorInfo: COLOR_OPTIONS.BLUE_50,
    colorSuccess: COLOR_OPTIONS.GREEN_PRIMARY,
    colorWarning: COLOR_OPTIONS.YELLOW_40,
    colorError: COLOR_OPTIONS.RED_50,
    colorLink: COLOR_OPTIONS.BLUE_50,
    colorPrimaryHover: COLOR_OPTIONS.GREEN_PRIMARY,
    colorPrimaryActive: COLOR_OPTIONS.GREEN_70,
    colorPrimaryBgHover: COLOR_OPTIONS.GREEN_10,
    colorPrimaryBorder: COLOR_OPTIONS.GREEN_40,
    colorPrimaryTextActive: COLOR_OPTIONS.GREEN_70,
    colorTextBase: COLOR_OPTIONS.NEUTRAL_100,
    colorBgBase: COLOR_OPTIONS.NEUTRAL_00,
    colorTextSecondary: COLOR_OPTIONS.NEUTRAL_60,
    colorBorder: COLOR_OPTIONS.NEUTRAL_30,
    colorSuccessHover: COLOR_OPTIONS.GREEN_60,
    colorSuccessBorder: COLOR_OPTIONS.GREEN_40,
    colorSuccessBg: COLOR_OPTIONS.GREEN_10,
    colorWarningHover: COLOR_OPTIONS.YELLOW_50,
    colorWarningBorder: COLOR_OPTIONS.YELLOW_30,
    colorWarningBg: COLOR_OPTIONS.YELLOW_10,
    colorErrorHover: COLOR_OPTIONS.RED_60,
    colorErrorBorder: COLOR_OPTIONS.RED_110,
    colorErrorBg: COLOR_OPTIONS.RED_10,
    colorInfoHover: COLOR_OPTIONS.BLUE_60,
    colorInfoBorder: COLOR_OPTIONS.BLUE_30,
    colorInfoBg: COLOR_OPTIONS.BLUE_10
  },
  components: {
    Button: {
      primaryShadow: '0 0px 0 rgba(5, 145, 255, 0.1)'
    },
    Typography: {
      fontFamily: FONT_FAMILY,
      fontSizeHeading1: TYPOGRAPHY_LEVEL.H1,
      fontSizeHeading2: TYPOGRAPHY_LEVEL.H2,
      fontSizeHeading3: TYPOGRAPHY_LEVEL.H3,
      fontSizeHeading4: TYPOGRAPHY_LEVEL.H4,
      fontSizeHeading5: TYPOGRAPHY_LEVEL.H5,
      titleMarginBottom: '0',
      titleMarginTop: '0'
    }
  }
};
