import { Icon } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './field-cluster-modal.styles';

interface FieldClusterFooterProps {
  onClickCreate?: () => void;
}

const FieldClusterFooter = ({ onClickCreate }: FieldClusterFooterProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <StyledButton data-testid='field-cluster-modal-footer' onClick={onClickCreate}>
      <Icon type='plus-circle' />
      {t('modals.organization_settings.field_cluster_card.modal.button_create')}
    </StyledButton>
  );
};

export default React.memo(FieldClusterFooter);
