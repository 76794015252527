export const TAGS_ENTITLEMENT_PREFIX = 'scouting.annotationtags';
export const TAGS_KEY_TRANSLATION_PREFIX = 'tags_';

export enum QuickFilter {
  TODAY = 'TODAY',
  LAST_3_DAYS = 'LAST_3_DAYS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_14_DAYS = 'LAST_14_DAYS'
}

export const QuickFilterValues: Record<keyof typeof QuickFilter, number> = {
  [QuickFilter.TODAY]: 0,
  [QuickFilter.LAST_3_DAYS]: 3,
  [QuickFilter.LAST_7_DAYS]: 7,
  [QuickFilter.LAST_14_DAYS]: 14
};

export type UserFiltersProps = Record<
  string,
  Record<
    string,
    Record<
      string,
      {
        fields: string[];
        tags: any[];
        quick_filter?: string | null;
      }
    >
  >
>;

export interface ErrorPayload {
  status: number;
  code: string;
  error: string;
  message: string;
  path: string;
  timestamp: string;
}

export enum RegionType {
  REGION = 'REGION',
  AREA = 'AREA'
}

export interface Messages {
  sidebar: {
    title: string;
    subtitle: string;
    noteTitle: string;
    tagsTranslations: Record<string, string>;
    photos: {
      uploadedFromCamera: string;
      uploadedFromGallery: string;
      createdBy: string;
      in: string;
      previousMedia: string;
      nextMedia: string;
      current: string;
      goToImage: string;
      download: string;
    };
    errorState: {
      title: string;
      ENOT002: string;
      subtitle: string;
    };
    filters: {
      title: string;
      cancelTitle: string;
      resetTitle: string;
      applyTitle: string;
      rangeDates: {
        label: string;
        quickFilters: {
          today: string;
          lastThreeDays: string;
          lastSevenDays: string;
          lastFourteenDays: string;
        };
      };
      fields: {
        label: string;
        placeholder: string;
        allFields: string;
      };
      tags: {
        label: string;
        placeholder: string;
        allTags: string;
      };
    };
  };
  map: {
    tip: string;
    clusterPopup: {
      note: string;
      groupedPoints: string;
    };
  };
  products: Record<string, string>;
}
